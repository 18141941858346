import { FC, useEffect } from 'react';

import { useHistory as useRouter } from 'react-router-dom';

import { useUser } from 'hooks/useUser';

const SignOut: FC = () => {
  const router = useRouter();
  const { user, signOut } = useUser();

  useEffect(() => {
    if (user) {
      console.log('signing user out');
      signOut();
      router.replace('/');
    }
  }, []);
  return <></>;
};

export default SignOut;
