import { FC } from 'react';

import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import Button from '../Button';

interface ModalTitleProps {
  subtitle?: string;
  onCloseClick?: (isOpen: boolean) => void;
}

const ModalTitle: FC<ModalTitleProps> = ({
  children,
  subtitle,
  onCloseClick
}) => {
  return (
    <Dialog.Title
      as="div"
      className="text-lg leading-6 font-medium text-gray-900"
    >
      <div className="flex flex-row">
        <div className="flex-grow text-left">
          <h3>{children}</h3>
          {subtitle && <p className="test-xs font-light">{subtitle}</p>}
        </div>
        <div>
          {onCloseClick && (
            <Button size="sm" onClick={() => onCloseClick(false)}>
              <XIcon className="h-4 w-4" aria-hidden="true" />
            </Button>
          )}
        </div>
      </div>
    </Dialog.Title>
  );
};

export default ModalTitle;
