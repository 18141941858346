import { FC, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';

import Logo from 'components/Logo';
import { useSiteConfig } from 'hooks/useSiteConfig';

import Alert from '../components/ui/Alert';
import Button from '../components/ui/Button';
import ButtonGroup from '../components/ui/ButtonGroup';
import Divider from '../components/ui/Divider';
import { useUser } from '../hooks/useUser';
import analytics from '../utils/analytics';
import { callApi } from '../utils/api';
import ErrorPage from './error';
import FallbackPage from './fallback';

interface AcceptInviteQuery {
  member_id: string;
  token: string;
}

const AcceptInvite: FC = () => {
  const { siteConfig } = useSiteConfig();
  const { member_id, token } = useParams() as AcceptInviteQuery;
  const history = useHistory();
  const { userDetails, signOut: logout, fetchUserDetails } = useUser();

  const [acceptStatus, setAcceptStatus] = useState({
    error: false,
    accepting: false
  });

  const { data, error } = useSWR(`/v1/accept-invite/${member_id}/${token}`);

  const makeNextUrl = (path: string) => {
    const params = new URLSearchParams({ next: window.location.pathname });
    return `${path}?${params.toString()}`;
  };

  const gotoNext = (path: string) => {
    history.push(makeNextUrl(path));
  };

  const handleAcceptInvite = async () => {
    setAcceptStatus((s) => ({ ...s, accepting: true }));

    try {
      await callApi(
        `/v1/accept-invite/${member_id}/${token}`,
        { method: 'POST' },
        false
      );
      await fetchUserDetails();

      analytics.track('Signed Up', {
        type: 'invite',
        email: userDetails?.email,
        context: {
          groupId: userDetails?.organisation?.slug
        }
      });

      history.replace(siteConfig.redirect || '/dashboard');
    } catch {
      setAcceptStatus((s) => ({ ...s, error: true }));
    }
    setAcceptStatus((s) => ({ ...s, accepting: false }));
  };

  const handleLogout = async () => {
    await logout();
    history.replace(makeNextUrl('/auth/signin'));
  };

  const acceptActions = () => {
    return (
      <ButtonGroup center={true} className="mt-6">
        <Button
          variant="primary"
          disabled={acceptStatus.accepting}
          onClick={handleAcceptInvite}
        >
          Join
        </Button>
      </ButtonGroup>
    );
  };

  const authActions = () => {
    return (
      <>
        <p>
          To continue, you must either create a new account, or login to an
          existing Climate Resource account.
        </p>
        <ButtonGroup center={true} className="mt-6">
          <Button variant="primary" onClick={() => gotoNext('/auth/register')}>
            Create a new account
          </Button>

          <Button variant="default" onClick={() => gotoNext('/auth/signin')}>
            Login
          </Button>
        </ButtonGroup>
      </>
    );
  };

  const alreadyExists = () => {
    return (
      <Alert variant="error">
        <p>
          Your account ({userDetails?.email}) is already a member of this
          organisation.
        </p>
        <ButtonGroup center={true} className="mt-6">
          <Button variant="error" onClick={handleLogout}>
            Switch Accounts
          </Button>
        </ButtonGroup>
      </Alert>
    );
  };

  if (error) {
    return <ErrorPage message={error.info?.details} statusCode={400} />;
  }
  if (!data) {
    return <FallbackPage />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo height={80} />
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h2 className="mt-3 text-center text-2xl text-gray-900">
            <b className="font-extrabold">{data.inviter}</b> has invited you to
            join the <b className="font-extrabold">{data.orgSlug}</b> team!
          </h2>

          <div className="mt-6">
            {data.needsAuthentication
              ? authActions()
              : data.existingMember
              ? alreadyExists()
              : acceptActions()}
          </div>

          <Divider />

          <h3>
            Climate Resource is a platform for interacting with the latest
            climate science
          </h3>
          {/*todo Add screenshot */}
        </div>
      </div>
    </div>
  );
};

export default AcceptInvite;
