import { FC } from 'react';

import classNames from 'classnames';
import { Row as ReactTableRow } from 'react-table';

type RowProps = {
  row: ReactTableRow;
  i: number;
  onClick?: () => void;
  hover: boolean;
};

const styles = {
  default: 'px-6 py-4 whitespace-nowrap text-sm text-gray-500',
  bold: 'px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900',
  condensed: 'w-2'
};

const Row: FC<RowProps> = ({ row, i, onClick, hover }: RowProps) => {
  return (
    <tr
      {...row.getRowProps()}
      className={classNames({
        'hover:bg-gray-200': hover,
        'bg-white': i % 2 === 0,
        'bg-gray-50': i % 2 !== 0,
        'cursor-pointer': !!onClick
      })}
    >
      {row.cells.map((cell) => {
        const cellProps = cell.getCellProps([
          {
            className: `${
              styles[cell.column.className] || cell.column.className
            }`
          },
          {
            className: styles.default
          }
        ]);
        const shouldPreventClick = (cell.column as any).state?.preventClick;
        return (
          <td
            {...cellProps}
            onClick={shouldPreventClick ? undefined : onClick}
            key={cell.column.id}
          >
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
};

export default Row;
