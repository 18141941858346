import { Auth } from '@aws-amplify/auth';
import FormData from 'form-data';

/* global RequestInit */

const rootUrl = process.env.REACT_APP_API_URL;

class APIError extends Error {
  status?: number;
  info?: any;
}

interface CallConfig extends RequestInit {
  data?: any;
}

async function callApi<T extends object = any>(
  url: string,
  config?: CallConfig,
  parseJSON = true
): Promise<T> {
  config = config || {};

  const headers = config.headers || {
    Accept: 'application/json'
  };

  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();

    headers['Authorization'] = `Bearer ${accessToken}`;
  } catch (err) {
    console.log(err);
  }
  if (config.data) {
    headers['Content-Type'] = 'application/json';
    config.body = JSON.stringify(config.data);
  }

  const options = { ...config, headers };
  const fullUrl = new URL(url, rootUrl);
  if (fullUrl.hostname !== 'localhost') fullUrl.protocol = 'https:'; // TODO: fix the stage hrefs in the backend

  const res = await fetch(fullUrl.href, options);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new APIError('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    try {
      error.info = await res.json();
    } catch (err) {
      console.log('could not parse JSON');
    }

    error.status = res.status;
    throw error;
  }

  if (parseJSON) {
    return (await res.json()) as T;
  } else {
    // This is a weird cast
    return res as T;
  }
}

async function uploadFile<T extends object = any>(
  url: string,
  file: File,
  extra?: Record<string, any>,
  method = 'POST'
): Promise<T> {
  const formData = new FormData();
  extra = extra || {};
  formData.append('file', file);

  for (const key in extra) {
    formData.append(key, extra[key]);
  }
  return await callApi<T>(url, {
    method: method,
    // @ts-ignore
    body: formData as FormData,
    headers: {
      'Content-Disposition': `attachment; filename=${file.name}`,
      'Media-Type': `text/csv`
    }
  });
}

export { callApi, uploadFile };
