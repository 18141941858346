import { FC } from 'react';

import { Form, Formik } from 'formik';

import Head from 'components/Head';
import AuthButton from 'components/ui/AuthButton';
import Button from 'components/ui/Button';
import Textbox from 'components/ui/Textbox';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useUser } from 'hooks/useUser';
import AuthLayout from 'layouts/AuthLayout';

const initialValues = {
  email: '',
  password: ''
};

const SignIn: FC = () => {
  const { signIn, loading } = useUser();
  const { siteConfig } = useSiteConfig();

  return (
    <>
      <Head>
        <title>{'Signin'}</title>
      </Head>
      <AuthLayout title="Sign into your account">
        <Formik initialValues={initialValues} onSubmit={signIn}>
          <Form className="space-y-6">
            <Textbox
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              required
            />
            <Textbox
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              required
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember_me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a
                  href="/auth/forgot-password"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <AuthButton loading={loading} text="Sign In" />
          </Form>
        </Formik>
        {siteConfig.allowSignups && (
          <div className="flex flex-col">
            <div className="inset-0 flex items-center h-12">
              <div className="w-full border-t border-gray-300" />
            </div>
            <Button href="/auth/create-org">Create an account</Button>
          </div>
        )}
      </AuthLayout>
    </>
  );
};

export default SignIn;
