import { FC, Fragment, useEffect, useRef, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

import Button from '../Button/Button';

type ModalWidth = 'default' | 'wide' | 'full';

const widths: Record<ModalWidth, string> = {
  default: 'sm:max-w-lg',
  wide: 'sm:max-w-3xl',
  full: 'sm:max-w-full'
};

interface ModalProps {
  onClose: (open: boolean) => void;
  open: boolean;
  initialOpen?: boolean;
  width: ModalWidth;
  showOk?: boolean;
  okLabel?: string;
  showCancel?: boolean;
}

const Modal: FC<ModalProps> = ({
  children,
  onClose,
  open,
  initialOpen = false,
  width = 'default',
  okLabel,
  showOk
}) => {
  const [localOpen, setLocalOpen] = useState(initialOpen);

  useEffect(() => {
    console.log(open);
    setLocalOpen(open);
  }, [open]);

  useEffect(() => {
    if (onClose) onClose(localOpen);
  }, [localOpen]);
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={localOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={localOpen}
        onClose={setLocalOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-full sm:p-6',
                widths[width]
              )}
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">{children}</div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                {showOk && (
                  <Button onClick={() => setLocalOpen(false)}>{okLabel}</Button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
