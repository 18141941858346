interface Passwords {
  password: string;
  confirmPassword: string;
}

const validate = ({
  password,
  confirmPassword
}: Passwords): Record<string, string> => {
  const errors: Record<string, string> = {};

  if (password !== confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
  }
  return errors;
};

export default validate;
