// Components relate to parts of the application which can be turned on/off
// They are intrinsic to a given deploy/site code
export enum Component {
  Account = 'account',
  Auth = 'auth',
  Pipeline = 'pipeline',
  Footer = 'footer',
  Marketing = 'marketing',

  // Client Specific
  DAE = 'dae',
  IEA = 'iea'
}

export type Components = Record<Component, boolean | string>;
