import { FC } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import useFeatures from 'hooks/useFeatures';
import { Feature } from 'typings/Feature';

export interface NavigationBarItemProps {
  name: string;
  href: string;
  isMobile: boolean;
  requiredFeatures?: Feature | Feature[];
}

const styles = {
  active: 'font-medium hover:underline',
  mobile: 'text-sm',
  desktop: 'block text-base',
  default: 'text-gray.700 px-3 py-2 rounded-md hover:underline hover:font-bold'
};

const NavbarItem: FC<NavigationBarItemProps> = ({
  name,
  href,
  isMobile = false,
  requiredFeatures = []
}) => {
  const { hasFeatures } = useFeatures();

  if (!hasFeatures(requiredFeatures)) {
    return null;
  }

  return (
    <NavLink
      key={name}
      to={href}
      activeClassName={styles.active}
      className={classNames(
        styles.default,
        isMobile ? styles.mobile : styles.desktop
      )}
    >
      {name}
    </NavLink>
  );
};

export default NavbarItem;
