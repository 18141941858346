import { Component } from 'typings/Component';
import { SiteConfig } from 'typings/SiteConfig';

const siteCode = process.env.REACT_APP_SITE_CODE || 'public';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require('../../site-config/' + siteCode) as SiteConfig;

const hasSiteComponent = (component: Component): boolean => {
  return config.components.includes(component);
};

const defaultRedirect = (): string => {
  return config.redirect || '/dashboard';
};

export { siteCode, hasSiteComponent, defaultRedirect };
export default config;
