import { FC, ReactNode } from 'react';

export interface FooterItemProps {
  name: string;
  href?: string;
  render?: () => ReactNode;
}

const FooterItem: FC<FooterItemProps> = ({ name, href, render }) => {
  return (
    <li key={name}>
      {href && (
        <a href={href} className="text-base text-gray-300 hover:text-white">
          {name}
        </a>
      )}
      {render && render()}
    </li>
  );
};

export default FooterItem;
