import { FC } from 'react';

import { PageHeading, PageBody } from 'components/Page';

const AccountProfile: FC = () => {
  return (
    <>
      <PageHeading title="Profile" />
      <PageBody>This is a test</PageBody>
    </>
  );
};

export default AccountProfile;
