import { FC } from 'react';

import { useField, FieldHookConfig } from 'formik';

import Checkbox, { CheckboxProps } from '../ui/Checkbox';

type BooleanFieldProps = FieldHookConfig<boolean> & CheckboxProps;

const BooleanField: FC<BooleanFieldProps> = (props) => {
  const [field, meta] = useField<boolean>(props);
  return (
    <div className="flex">
      <Checkbox {...field} {...props} />

      {meta.touched && meta.error ? (
        <div className="mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default BooleanField;
