import { FC } from 'react';

import { CheckIcon } from '@heroicons/react/solid';

type CheckCellProps = {
  value: boolean;
};

const CheckCell: FC<CheckCellProps> = ({ value }) => {
  if (value) {
    return <CheckIcon className="h-5" />;
  } else {
    return null;
  }
};

export default CheckCell;
