import React, { FC } from 'react';

import Footer from '../../components/Footer';
import NavigationBar from '../../components/NavigationBar';
import { Component } from '../../typings/Component';
import { hasSiteComponent } from '../../utils/siteConfig';

export interface BasicLayoutProps {
  children: React.ReactNode;
}

export const BasicLayout: FC<BasicLayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col">
      <NavigationBar />
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        {children}
      </main>
      {hasSiteComponent(Component.Footer) && <Footer />}
    </div>
  );
};
