import { FC } from 'react';

interface LabelProps {
  name: string;
}

const Label: FC<LabelProps> = ({ name, children }) => {
  return (
    <label
      htmlFor={name}
      className="block text-sm font-medium text-gray-700 flex flex-row items-center"
    >
      {children}
    </label>
  );
};

export default Label;
