import { AnalyticsInstance } from 'analytics';

/* eslint-disable @typescript-eslint/no-empty-function */
const analytics: AnalyticsInstance = {
  identify: () => {
    return Promise.resolve({});
  },
  track: () => {
    return Promise.resolve({});
  },
  page: () => {
    return Promise.resolve({});
  },
  user: () => {
    return Promise.resolve({});
  },
  reset: () => {
    return Promise.resolve({});
  },
  ready: () => {
    return () => {};
  },
  once: () => {
    return () => {};
  },
  on: () => {
    return () => {};
  },
  getState: () => {},
  storage: {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {}
  },
  plugins: {
    enable: () => {
      return Promise.resolve({});
    },
    disable: () => {
      return Promise.resolve({});
    }
  }
};

export default analytics;
