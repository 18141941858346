import { FC } from 'react';

import ReactTooltip, { TooltipProps as ReactTooltipProps } from 'react-tooltip';

export interface TooltipProps extends ReactTooltipProps {
  id: string;
  label?: string;
  showArrow?: boolean;
}

const Tooltip: FC<TooltipProps> = ({
  id,
  effect = 'solid',
  label,
  showArrow = true,
  children,
  ...rest
}) => {
  return (
    <>
      <span data-for={id} data-tip={label}>
        {children}
      </span>
      <ReactTooltip
        role="tooltip"
        id={id}
        effect={effect}
        arrowColor={!showArrow ? 'transparent' : undefined}
        multiline={true}
        className="max-w-full font-normal"
        {...rest}
      />
    </>
  );
};

export default Tooltip;
