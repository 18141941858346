import React, { FC } from 'react';

import classNames from 'classnames';

import { Variant } from 'components/ui/Button/Button';
import Icon from 'components/ui/Icon';

import Button from '../ui/Button';

export interface CardHeaderActions {
  label?: string;
  onClick?: () => void;
  variant?: Variant;
  render?: (renderProps: any) => React.ReactNode;
  Icon?: Icon;
}

export interface CardHeaderProps {
  title?: string;
  subtitle?: string;
  actions?: CardHeaderActions[];
  hasBorder?: boolean;
}

const CardHeader: FC<CardHeaderProps> = ({
  title,
  subtitle,
  actions = [],
  hasBorder = false,
  children
}) => {
  return (
    <div data-testid="card-header" className="pt-6 px-4 sm:px-6">
      <div
        className={classNames('flex flex-row', {
          'border-b-2 border-gray-200 pb-4': hasBorder
        })}
        style={{ minHeight: '60px' }}
      >
        <div className="flex-grow">
          {title && (
            <h2 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h2>
          )}
          {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
          {children}
        </div>
        {actions.map(({ label, onClick, variant, render, Icon }, idx) => {
          if (render) {
            return <div key={idx}>{render({ label, onClick, variant })}</div>;
          }
          return (
            <Button onClick={onClick} variant={variant} key={idx}>
              {Icon && (
                <Icon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              )}
              {label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default CardHeader;
