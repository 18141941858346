// @ts-nocheck

import { FC } from 'react';

import * as Sentry from '@sentry/browser';

import { PageBody } from '../components/Page';
const ForceError: FC = () => {
  try {
    1 / 0;
  } catch (error) {
    Sentry.captureException(error);
  }

  return (
    <div data-testid="fallback">
      <PageBody>
        <h1>Testing</h1>
        {/* eslint-disable-next-line no-undef */}
        <button onClick={missingFunc}>Throw</button>
      </PageBody>
    </div>
  );
};

export default ForceError;
