import { FC } from 'react';

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import { HeaderGroup as HeaderGroupType } from 'react-table';

interface HeaderGroup extends HeaderGroupType {
  disableSortBy?: boolean;
}

type HeaderItemProps = {
  column: HeaderGroup;
  className: string;
};

const styles = {
  sortIndicator: 'h-4 ml-2 inline-block'
};

const HeaderItem: FC<HeaderItemProps> = ({ column, ...rest }) => {
  return (
    <th {...rest} {...column.getHeaderProps(column.getSortByToggleProps())}>
      {column.render('Header')}
      {!column?.disableSortBy && (
        <span>
          {column.isSorted &&
            (column.isSortedDesc ? (
              <ArrowDownIcon className={styles.sortIndicator} />
            ) : (
              <ArrowUpIcon className={styles.sortIndicator} />
            ))}
        </span>
      )}
    </th>
  );
};

export default HeaderItem;
