import { FC } from 'react';

import classNames from 'classnames';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { Link } from 'components/ui/typography';

export interface BreadcrumbsProps {
  className?: string;
  routes?: BreadcrumbsRoute[] | undefined;
}

const defaultStyle = 'mr-2 underline hover:underline';

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  className,
  routes = [],
  ...rest
}) => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <div key={match.url}>
          {index !== 0 && (
            <span className={classNames(defaultStyle, className)}>{'>'}</span>
          )}
          <Link
            href={match.url}
            className={classNames(defaultStyle, className)}
            {...rest}
          >
            {breadcrumb}
          </Link>
        </div>
      ))}
    </>
  );
};

export default Breadcrumbs;
