import React, { FunctionComponent } from 'react';

import { SubHeading as SubHeadingType } from './types';

export type SubHeadingProps = SubHeadingType;

const SubHeading: FunctionComponent<SubHeadingProps> = ({ label, Icon }) => {
  return (
    <div className="mt-2 flex items-center text-sm text-gray-500">
      {Icon ? (
        <span
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        >
          <Icon />
        </span>
      ) : undefined}

      {label}
    </div>
  );
};

export default SubHeading;
