import { useLocation } from 'react-router-dom';

const useQuery = (): Record<string, any> => {
  const location = useLocation();

  const params = new URLSearchParams(location.search.trimLeft());

  return Object.fromEntries(params.entries());
};

export default useQuery;
