import { useContext } from 'react';

import { Feature, FeatureValue, Features } from 'typings/Feature';

import { UserContext } from './useUser';

type MatchCallback = (v: FeatureValue) => boolean;
type HasFeatures = (
  requiredFeatures: Feature | Feature[],
  match?: MatchCallback
) => boolean;

interface UseFeaturesProps {
  hasFeatures: HasFeatures;
}

// By default check if feature is truthy
const defaultMatch = (v: FeatureValue) => Boolean(v);

export const checkHasFeatures = (
  features: Features,
  requiredFeatures: Feature[],
  match: MatchCallback = defaultMatch
): boolean => {
  return requiredFeatures
    .map((f) => match(features[f] as Feature))
    .every((v) => v);
};

const useFeatures = (): UseFeaturesProps => {
  const context = useContext(UserContext);

  const hasFeatures: HasFeatures = (requiredFeatures, match = defaultMatch) => {
    const featArray = Array.isArray(requiredFeatures)
      ? requiredFeatures
      : [requiredFeatures];
    if (context.userDetails) {
      return checkHasFeatures(context.userDetails.features, featArray, match);
    }
    return false;
  };

  return {
    hasFeatures
  };
};

export default useFeatures;
