import { lazy, ComponentType, LazyExoticComponent } from 'react';

type LazyImport = () => Promise<{ default: ComponentType<any> }>;

const MAX_RETRIES = 5;
const INTERVAL = 500;

const retry = (
  fn: LazyImport,
  retriesLeft = MAX_RETRIES
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((err) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(new Error(`${err} after ${MAX_RETRIES} retries`));
            return;
          }
          retry(fn, retriesLeft - 1).then(resolve, reject);
        }, INTERVAL);
      });
  });
};

export const load = (fn: LazyImport): LazyExoticComponent<ComponentType<any>> =>
  lazy(() => retry(() => fn()));

export const buildUrl = (
  base: string,
  queryParams:
    | Array<URLSearchParams | Record<string, any>>
    | Record<string, any>
): string => {
  const url = new URL(base, window.location.toString());
  const mergedParams = new URLSearchParams(url.searchParams);

  if (!Array.isArray(queryParams)) queryParams = [queryParams];

  queryParams.forEach((p) => {
    if (!(p instanceof URLSearchParams)) p = new URLSearchParams(p);
    return p.forEach((value, key) => mergedParams.append(key, value));
  });
  url.search = mergedParams.toString();

  return `${url.pathname}${url.search}`;
};
