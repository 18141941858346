import { FC } from 'react';

import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import intlFormat from 'date-fns/intlFormat';
import ReactTooltip from 'react-tooltip';

export interface DateItemProps {
  date: string | number | Date;
}

const DateItem: FC<DateItemProps> = ({ date }) => {
  const jsDate = new Date(date);
  try {
    const distance = `${formatDistanceToNow(jsDate)} ago`;
    const formatted = intlFormat(jsDate, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
    return (
      <>
        <span data-tip="React-tooltip" data-for="registerTip">
          {distance}
        </span>
        <ReactTooltip id="registerTip" aria-haspopup="true">
          <span data-testid="long-date"> {formatted}</span>
        </ReactTooltip>
      </>
    );
  } catch (e) {
    return <span>a while ago</span>;
  }
};

export default DateItem;
