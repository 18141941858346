import { FC } from 'react';

export interface LogoProps {
  height?: string | number;
  width?: string | number;
}

const Logo: FC<LogoProps> = ({ height = 99, width = 375 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 374.52 99.17"
    height={height}
    width={width}
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            fill="#005e72"
            d="M14.28,95H19a4.17,4.17,0,0,1,.37,1.66,1.26,1.26,0,0,1-.3,1,1.75,1.75,0,0,1-1.1.26H3.79a5.31,5.31,0,0,1-.34-1.79,1.09,1.09,0,0,1,.29-.83,1.37,1.37,0,0,1,1-.27h3Q8,90.2,8,70.63q0-10.42-.3-15.2H4.15a5.14,5.14,0,0,1-.36-1.8,1.1,1.1,0,0,1,.28-.83,1.5,1.5,0,0,1,1-.26h13q10,0,14.21,2.72t4.22,8.6q0,9.4-11.09,12.72a9.76,9.76,0,0,1,5.35,3.57,39.06,39.06,0,0,1,4.18,7.67q2.19,5,3.4,6.12a4.6,4.6,0,0,0,3.37,1c.42,1.4.38,2.34-.13,2.82s-1.75.74-3.72.74a9.83,9.83,0,0,1-5-1c-1.08-.66-2.3-3.19-3.63-7.59s-2.74-7.41-4.2-9-3.62-2.45-6.48-2.45a31.86,31.86,0,0,0-4.55.33Q14,91,14.28,95ZM14,74.82h2.82q6.48,0,9.78-2.23a7.32,7.32,0,0,0,3.31-6.47q0-4.79-2.91-7.11t-8.65-2.32a21.69,21.69,0,0,0-4.08.33Q14,60.22,14,71.2Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M61.33,64.69q5.81,0,8.73,2.82T73,77c0,1.86-.31,3.13-.93,3.83s-2,1-4.15,1H52.23c0,3.92.77,7,2.26,9.26s4.1,3.39,7.87,3.39A16.7,16.7,0,0,0,71.55,92a3.72,3.72,0,0,1,1.3,2.69c0,1.37-1.2,2.34-3.59,2.89a35.07,35.07,0,0,1-7.87.83q-8.23,0-11.77-3.6T46.09,82.62q0-8.27,3.95-13.1T61.33,64.69Zm-1.27,3.85a6.64,6.64,0,0,0-5.62,2.56c-1.3,1.7-2,4.25-2.18,7.64h4.22c4.18,0,6.93-.08,8.25-.22s2.08-.38,2.29-.71a4.11,4.11,0,0,0,.32-2,7.5,7.5,0,0,0-1.85-5.35A7.12,7.12,0,0,0,60.06,68.54Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M93.3,64.69a22.78,22.78,0,0,1,6.59.78c1.67.52,2.51,1.37,2.51,2.54a5.19,5.19,0,0,1-1.53,3.49q-6.24-3-10-3a7.08,7.08,0,0,0-4.22,1,3.57,3.57,0,0,0-1.43,3.07A4.22,4.22,0,0,0,86.74,76a21.7,21.7,0,0,0,5.86,3q5.69,2,8.11,4.12a7.31,7.31,0,0,1,2.42,5.84,8.22,8.22,0,0,1-3.45,7.12q-3.47,2.48-10.13,2.48a31.52,31.52,0,0,1-7.24-.67c-1.79-.44-2.69-1.37-2.69-2.79a4.72,4.72,0,0,1,1.16-3,22.44,22.44,0,0,0,10,2.59,9.21,9.21,0,0,0,5.16-1.14A4,4,0,0,0,97.58,90a4.57,4.57,0,0,0-2.39-4.1,48.77,48.77,0,0,0-7.37-3.44c-3.16-1.22-5.32-2.48-6.46-3.8a7.36,7.36,0,0,1-1.71-5A7.69,7.69,0,0,1,83,67.18Q86.4,64.69,93.3,64.69Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M123.18,98.53q-7.07,0-10.29-3.79t-3.22-11.69q0-8.65,3.88-13.51t11.39-4.85q7.08,0,10.28,3.79t3.2,11.65q0,8.7-3.88,13.55T123.18,98.53Zm1.1-3.86c2.88,0,4.93-1.06,6.18-3.18s1.85-5.14,1.85-9q0-6.88-1.85-10.4c-1.25-2.34-3.46-3.52-6.65-3.52-2.87,0-4.93,1.07-6.17,3.19s-1.86,5.14-1.86,9q0,6.87,1.88,10.39C118.91,93.5,121.11,94.67,124.28,94.67Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M172,85.11q0,6.74.55,8.24a1.74,1.74,0,0,0,2,1.32,2.52,2.52,0,0,1,.37,1.3c0,.69-.55,1.23-1.65,1.63a9.82,9.82,0,0,1-3.33.59,2,2,0,0,1-1.45-.46,11.54,11.54,0,0,1-1.51-2.59,26.89,26.89,0,0,1-5.33,2.44,18.49,18.49,0,0,1-5.89,1,8.25,8.25,0,0,1-6.26-2.31q-2.2-2.31-2.21-8,0-12.56-.11-15.16a10.38,10.38,0,0,0-.55-3.5,1.91,1.91,0,0,0-1.16-1.1,5.33,5.33,0,0,0-1.36-.2,2.49,2.49,0,0,1-.37-1.29c0-.69.51-1.14,1.53-1.36a18.8,18.8,0,0,1,3.88-.33,9.82,9.82,0,0,1,2.71.24,1.31,1.31,0,0,1,.86.87,25.26,25.26,0,0,1,.18,4.2V83.42c0,4.65.56,7.68,1.66,9.11A5.42,5.42,0,0,0,159,94.67a10.4,10.4,0,0,0,4-.81,4.82,4.82,0,0,0,2.69-2.34q.71-1.53.71-6,0-9.84-.12-12.32a11.81,11.81,0,0,0-.51-3.5,1.86,1.86,0,0,0-1.08-1.22,5.58,5.58,0,0,0-1.54-.2,2.49,2.49,0,0,1-.37-1.29c0-.69.55-1.14,1.65-1.36a19.41,19.41,0,0,1,3.8-.33,9.69,9.69,0,0,1,2.7.24,1.34,1.34,0,0,1,.87.92,26.35,26.35,0,0,1,.18,4.25Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M181.19,66.62a.89.89,0,0,1,.61-1,69.8,69.8,0,0,1,7.79-.41,21.83,21.83,0,0,1,0,4.15,22.57,22.57,0,0,1,13.15-4.49,2.32,2.32,0,0,1,1.09.17c.18.11.27.37.27.76a10.3,10.3,0,0,1-.4,2.73,3.31,3.31,0,0,1-.95,1.84,4.6,4.6,0,0,1-1.85.58,4.23,4.23,0,0,1-1.57.08A8,8,0,0,1,198,69.44a7.46,7.46,0,0,0-5.25,1.91c-1.37,1.27-2.05,3.55-2.05,6.82q0,11.82.29,16.8h4.42a3.85,3.85,0,0,1,.33,1.6,1.32,1.32,0,0,1-.31,1,2,2,0,0,1-1.18.26H181.52a4.91,4.91,0,0,1-.33-1.69,1.19,1.19,0,0,1,.31-.9,1.5,1.5,0,0,1,1.05-.3h2.26q.27-5,.26-13.71,0-10.07-.69-11.54a2.83,2.83,0,0,0-2.83-1.48A3.73,3.73,0,0,1,181.19,66.62Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M222.73,98.53q-7.71,0-11.06-3.47t-3.35-11.74q0-9.16,4.16-13.9t12.77-4.73a17.47,17.47,0,0,1,6,.78c1.31.52,2,1.26,2,2.21a5.43,5.43,0,0,1-1.3,3.39,18.16,18.16,0,0,0-8.27-2.53q-5.15,0-7.22,3.17t-2.07,9.48q0,6.65,2,10.06t7.37,3.42a19.05,19.05,0,0,0,8.87-2.59,4.72,4.72,0,0,1,1,2.73q0,1.72-3.4,2.72A26.88,26.88,0,0,1,222.73,98.53Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M255,64.69q5.82,0,8.74,2.82t3,9.47c0,1.86-.31,3.13-.93,3.83s-2,1-4.15,1h-15.7c0,3.92.77,7,2.25,9.26s4.11,3.39,7.87,3.39a16.71,16.71,0,0,0,9.2-2.56,3.74,3.74,0,0,1,1.29,2.69c0,1.37-1.19,2.34-3.58,2.89a35.07,35.07,0,0,1-7.87.83q-8.23,0-11.77-3.6t-3.54-12.14q0-8.27,4-13.1T255,64.69Zm-1.26,3.85a6.66,6.66,0,0,0-5.63,2.56c-1.29,1.7-2,4.25-2.17,7.64h4.22c4.18,0,6.93-.08,8.25-.22s2.08-.38,2.29-.71a4.14,4.14,0,0,0,.31-2,7.49,7.49,0,0,0-1.84-5.35A7.13,7.13,0,0,0,253.77,68.54Z"
            transform="translate(-3.45 -4.69)"
          />
        </g>
        <g>
          <path
            fill="#005e72"
            d="M62.16,53.17a19.44,19.44,0,0,0,10.75-3,4.61,4.61,0,0,1,1.53,3.36c0,1.1-1.37,2-4.1,2.74a39.84,39.84,0,0,1-10,1.08q-9.36,0-13.75-4.59T42.2,36.41q0-13.24,5.1-19.49t15.32-6.25a30.38,30.38,0,0,1,8.25.9c2,.6,3.07,1.56,3.07,2.89a4.36,4.36,0,0,1-1.69,3.22,25.69,25.69,0,0,0-11.19-2.85q-6.08,0-9.16,4.34T48.81,33.55q0,10.8,3.23,15.21T62.16,53.17Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M84.47,36.21c0-9.06,0-14.85-.14-17.4A23.3,23.3,0,0,0,83.87,14a1.9,1.9,0,0,0-1.1-1.33,5.54,5.54,0,0,0-1.92-.29,4.35,4.35,0,0,1-.37-1.69,1,1,0,0,1,.75-1.08,29.83,29.83,0,0,1,4.9-.21,13.33,13.33,0,0,1,3,.21,1.1,1.1,0,0,1,.88.87,16,16,0,0,1,.12,2.44V41.69q0,7.31.26,12.08h3.42a3.46,3.46,0,0,1,.37,1.6,1.32,1.32,0,0,1-.32,1,2,2,0,0,1-1.21.26H80.82A4.71,4.71,0,0,1,80.48,55a1.22,1.22,0,0,1,.3-.9,1.48,1.48,0,0,1,1.06-.3h2.33Q84.47,49,84.47,36.21Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M110,53.77h3.32a3.85,3.85,0,0,1,.33,1.6,1.32,1.32,0,0,1-.32,1,1.94,1.94,0,0,1-1.17.26H100.41a4.71,4.71,0,0,1-.34-1.69,1.19,1.19,0,0,1,.32-.9,1.49,1.49,0,0,1,1-.3h2.36c.18-3.19.27-7.08.27-11.69q0-7.63-.12-10.24a10.38,10.38,0,0,0-.55-3.5,1.86,1.86,0,0,0-1.21-1.1,7.17,7.17,0,0,0-1.74-.2,2.71,2.71,0,0,1-.37-1.42,1.27,1.27,0,0,1,1.2-1.33,27.26,27.26,0,0,1,4.45-.23,12.13,12.13,0,0,1,3,.23,1.08,1.08,0,0,1,.86.86,18.24,18.24,0,0,1,.1,2.43v14.5Q109.7,49,110,53.77Zm-4.09-36a4,4,0,0,1-2.7-.78,3,3,0,0,1-.88-2.34,3.3,3.3,0,0,1,1.14-2.58,4.66,4.66,0,0,1,3.11-.94,3.87,3.87,0,0,1,2.8.83,2.94,2.94,0,0,1,.85,2.22,3.38,3.38,0,0,1-1.06,2.61A4.71,4.71,0,0,1,105.88,17.75Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M148.32,35q0,14.91.3,18.82h3.45a3.58,3.58,0,0,1,.36,1.6,1.32,1.32,0,0,1-.31,1,2,2,0,0,1-1.21.26H140.15a4.57,4.57,0,0,1-.37-1.69,1.19,1.19,0,0,1,.32-.9,1.55,1.55,0,0,1,1.08-.3h1.26q.3-4.49.3-14.67,0-6.84-1.56-9.08a5.6,5.6,0,0,0-5-2.25,7.88,7.88,0,0,0-4.6,1.65c-1.54,1.09-2.3,2.94-2.3,5.53v7.13c0,4.48.08,8.37.26,11.69h2.82a3.86,3.86,0,0,1,.34,1.6,1.32,1.32,0,0,1-.32,1,2,2,0,0,1-1.18.26H120.13A4.91,4.91,0,0,1,119.8,55a1.19,1.19,0,0,1,.31-.9,1.5,1.5,0,0,1,1-.3h2.26q.26-5.18.26-13.71,0-10.07-.7-11.54A2.8,2.8,0,0,0,120.16,27a3.73,3.73,0,0,1-.36-1.62.89.89,0,0,1,.61-1,69.8,69.8,0,0,1,7.79-.41,19.48,19.48,0,0,1,0,3.91,27,27,0,0,1,6.09-3.18A16.79,16.79,0,0,1,140,23.72,8.9,8.9,0,0,1,145,24.94a5.83,5.83,0,0,1,2.38,3,24.23,24.23,0,0,1,5.94-3.17,16.76,16.76,0,0,1,5.61-1.05,8.89,8.89,0,0,1,6.18,2q2.22,2,2.22,7.56,0,18.59.48,19.55t2.28.93a4.82,4.82,0,0,1,.3,1.63,1,1,0,0,1-.45,1,3.89,3.89,0,0,1-1.71.25c-2.81,0-4.5,0-5.06-.08a1.48,1.48,0,0,1-1.15-.53,2.68,2.68,0,0,1-.3-1.45V39.1q0-6.84-1.54-9.08a5.56,5.56,0,0,0-4.93-2.25,7.9,7.9,0,0,0-4.63,1.65C149.09,30.51,148.32,32.36,148.32,35Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M196.66,53.94a23.76,23.76,0,0,1-5.76,2.59,20.58,20.58,0,0,1-5.63.8q-4.35,0-6.52-2.06T176.57,49a8.89,8.89,0,0,1,3.52-7.53q3.53-2.66,10.76-2.66h5.48c-.13-4.14-.7-7-1.69-8.68s-2.9-2.48-5.72-2.48a19.44,19.44,0,0,0-4,.4,10.61,10.61,0,0,1-1,1.93,8.64,8.64,0,0,1-1.72.56c-1,.29-1.77.25-2.18-.11a5.89,5.89,0,0,1-1.24-2,6.4,6.4,0,0,1-.63-2.34,1,1,0,0,1,.48-.88,18.75,18.75,0,0,1,4.2-.85,56.74,56.74,0,0,1,7.93-.53c3.94,0,6.77.68,8.47,2s2.56,3.76,2.56,7.24V44q0,7,.6,8.52c.39,1,1,1.42,1.92,1.24A2.49,2.49,0,0,1,204.6,55c0,.62-.41,1.11-1.23,1.46A8.55,8.55,0,0,1,200,57a3.88,3.88,0,0,1-1.62-.26C198,56.55,197.46,55.62,196.66,53.94Zm-.33-11.29a40.88,40.88,0,0,0-6.41-.5q-4.24,0-5.91,1.41a5.19,5.19,0,0,0-1.66,4.2A5.84,5.84,0,0,0,183.78,52a6.14,6.14,0,0,0,4.51,1.44,11.28,11.28,0,0,0,4.5-.91,5,5,0,0,0,2.84-2.51,14.77,14.77,0,0,0,.7-5.44Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M212.17,24.25h2.25l2.4-9.16a8.1,8.1,0,0,1,2.05.91A3,3,0,0,1,220,17.23q.31.72.32,5.86v1.16h10.79a6.47,6.47,0,0,1,.33,2.1,1.36,1.36,0,0,1-.33,1.09,2.18,2.18,0,0,1-1.3.27h-9.49V44.58c0,3.71.36,6.13,1.08,7.23a3.67,3.67,0,0,0,3.07,1.76,10.44,10.44,0,0,0,4.88-1.29A3.86,3.86,0,0,1,230,54.4a2.08,2.08,0,0,1-1.74,2.06,20.66,20.66,0,0,1-5.56.53q-4.12,0-6.06-1.71c-1.3-1.14-1.94-3.21-1.94-6.22V40.29q0-6.74-.37-12.58H211.1a7.17,7.17,0,0,1-.33-2.06,1.49,1.49,0,0,1,.32-1.06A1.45,1.45,0,0,1,212.17,24.25Z"
            transform="translate(-3.45 -4.69)"
          />
          <path
            fill="#005e72"
            d="M251.91,23.49c3.87,0,6.79.94,8.73,2.82s2.94,5,3,9.47c0,1.86-.31,3.13-.93,3.83s-2,1-4.15,1H242.81c0,3.92.78,7,2.26,9.26s4.11,3.39,7.87,3.39a16.74,16.74,0,0,0,9.2-2.56,3.74,3.74,0,0,1,1.29,2.69c0,1.37-1.19,2.34-3.58,2.89a35.13,35.13,0,0,1-7.87.83q-8.23,0-11.77-3.6t-3.54-12.14q0-8.27,4-13.1T251.91,23.49Zm-1.26,3.85A6.66,6.66,0,0,0,245,29.9c-1.29,1.7-2,4.25-2.17,7.64h4.21c4.19,0,6.94-.08,8.25-.22s2.08-.38,2.3-.71a4.14,4.14,0,0,0,.31-2,7.49,7.49,0,0,0-1.84-5.35A7.13,7.13,0,0,0,250.65,27.34Z"
            transform="translate(-3.45 -4.69)"
          />
        </g>
        <g>
          <circle fill="#005e72" cx="324.94" cy="49.58" r="49.58" />
          <circle fill="#fff" cx="325.21" cy="49.57" r="43.19" />
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
