import { FC, useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';

import InviteMembersModal from 'components/members/InviteMembersModal';
import { PageHeading, PageBody } from 'components/Page';

const AccountMembers: FC = () => {
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const actions = [
    {
      label: 'Add Member',
      Icon: PlusIcon,
      onClick: () => {
        setOpenInviteModal(true);
      }
    }
  ];

  return (
    <>
      <PageHeading title="Members" actions={actions} />
      <PageBody>Page body</PageBody>
      <InviteMembersModal
        open={openInviteModal}
        onClose={() => {
          setOpenInviteModal(false);
        }}
      />
    </>
  );
};

export default AccountMembers;
