import { FC } from 'react';

import classNames from 'classnames';
import { FieldProps, ErrorMessage } from 'formik';

import Label from '../Label';

export interface TextareaProps {
  name: string;
  label?: string;
  className?: string;
  width?: string;
  rows?: number;
  fieldProps?: FieldProps;
}

const baseClasses =
  'appearance-none block w-full px-3 py-2 border border-gray-300 disabled:bg-gray-100 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm';

const Textarea: FC<TextareaProps> = ({
  className,
  label,
  name,
  rows = 4,
  ...rest
}) => {
  return (
    <div>
      {label && <Label name={name}>{label}</Label>}
      <textarea
        name={name}
        rows={rows}
        className={classNames(baseClasses, className)}
        {...rest}
      />
      <ErrorMessage name={name} />
    </div>
  );
};

export default Textarea;
