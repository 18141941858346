import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import Head from 'components/Head';
import AuthButton from 'components/ui/AuthButton';
import { Link, Paragraph } from 'components/ui/typography';
import useUser from 'hooks/useUser';
import AuthLayout from 'layouts/AuthLayout';

const OrganisationMissing: FC = () => {
  const { signOut } = useUser();
  const history = useHistory();

  const handleClick = () => {
    signOut();
    history.replace('/auth/signin');
  };
  return (
    <>
      <Head>
        <title>{'Error'}</title>
      </Head>
      <AuthLayout title="Join an Organisation" redirect={false}>
        <Paragraph>
          You are not a member of an existing organisation. We are currently in
          a closed invite-only beta.
        </Paragraph>

        <Paragraph>
          If you believe this is an error contact{' '}
          <Link href="mailto:platform@climate-resource.com">support</Link> for
          assistance
        </Paragraph>
        <AuthButton loading={false} text={'Sign Out'} onClick={handleClick} />
      </AuthLayout>
    </>
  );
};

export default OrganisationMissing;
