import { FC, useState } from 'react';

import { Form, Formik } from 'formik';
import * as yup from 'yup';

import BooleanField from 'components/forms/BooleanField';
import Head from 'components/Head';
import AuthButton from 'components/ui/AuthButton';
import Button from 'components/ui/Button';
import Textbox from 'components/ui/Textbox';
import { Link } from 'components/ui/typography';
import { RegisterProps, useUser } from 'hooks/useUser';
import AuthLayout from 'layouts/AuthLayout';
import { Organisation } from 'typings/User';
import analytics from 'utils/analytics';
import { callApi } from 'utils/api';

interface OrgInfoValues {
  name: string;
  organisationName: string;
}

interface RegisterPasswordForm extends RegisterProps {
  email: string;
  password: string;
  confirmPassword: string;
}

enum FormSteps {
  UserInfo = 1,
  OrgInfo,
  LicenceTerms,
  Success,
  SubmitError = 99
}

const userInfoSchema = yup.object().shape({
  email: yup.string().email().required('No email was provided'),
  password: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character'
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], "Password doesn't match")
    })
});

const UserInfo = ({ onNext }) => {
  const initialValues: RegisterPasswordForm = {
    email: '',
    password: '',
    confirmPassword: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onNext}
      validationSchema={userInfoSchema}
      validateOnChange={false}
    >
      <Form className="space-y-6" noValidate>
        <Textbox
          name="email"
          type="email"
          label="Email"
          autoComplete="email"
          required
        />
        <Textbox
          name="password"
          type="password"
          label="Password"
          autoComplete="current-password"
          required
        />
        <Textbox
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          required
        />
        <AuthButton loading={false} text={'Next'} />
      </Form>
    </Formik>
  );
};

const OrgInfo = ({ onNext }) => {
  const initialValues: OrgInfoValues = {
    name: '',
    organisationName: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onNext}
      validateOnChange={false}
    >
      <Form className="space-y-6">
        <p>To get started we need some information about yourself</p>
        <Textbox
          name="name"
          type="text"
          label="Full Name"
          autoComplete="name"
          placeholder="John Smith"
          spellCheck={false}
          required
        />
        <Textbox
          name="organisationName"
          type="text"
          label="What is the name of your team?"
          placeholder="Team Name"
          tooltip="You can easily share you scenarios with other members of the same team. A team name will be autogenerated if nothing is provided."
        />
        <AuthButton loading={false} text={'Next'} />
      </Form>
    </Formik>
  );
};

const LicenseTerms = ({ onNext, loading }) => {
  const initialValues = {
    subscribe: false,
    iAgree: false
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onNext}
      validateOnChange={false}
    >
      <Form className="space-y-6">
        <p>Welcome to the MAGICC community.</p>
        <p className="text-indigo">
          We hope you can enjoy what has taken more than 30 years to develop and
          is still expanding. We also look forward to releasing an open-source
          version with all the bells and whistles in the next 24 months. Stay
          tuned and we hope you can make use of this only portal.
        </p>

        <p>Malte, Zeb and Jared</p>
        <p>
          <small>
            PS: Very rarely, we will send out emails if we updated things here
            in the portal etc. We won’t spam you with other stuff. Completely
            fine, if you do not want to receive updates (as we get too many
            emails, too).
          </small>
        </p>
        <BooleanField name="subscribe" label="Subscribe to emails">
          I would like to receive updates via email
        </BooleanField>
        <BooleanField name="iAgree" label="termsAndConditions" required>
          I agree to the <Link href="/terms">Terms of Service</Link> and{' '}
          <Link href="/privacy-policy">Privacy Policy</Link>
        </BooleanField>
        <AuthButton loading={loading} text={'Register'} />
      </Form>
    </Formik>
  );
};

const SubmitComplete = () => {
  return (
    <div className="flex text-center justify-center">
      <Button href={'/'}>Back</Button>
    </div>
  );
};

// Create a new account and organisation
const CreateOrg: FC = () => {
  const { register, loading, setMessage } = useUser();

  const [step, setStep] = useState(FormSteps.UserInfo);
  const [data, setData] = useState({});

  const handleNext = async (formState) => {
    const newData = { ...data, ...formState };
    setData(newData);
    if (step == FormSteps.LicenceTerms) {
      try {
        const userSub = await register(data as RegisterPasswordForm);

        // @ts-ignore
        const teamName = data.organisationName || data.name + "'s Team";

        if (userSub) {
          const organisation = await callApi<Organisation>('/v1/organisation', {
            method: 'POST',
            data: {
              name: teamName,
              userSub
            }
          });

          // @ts-ignore
          analytics.plugins.segment.group(organisation.slug, {
            id: organisation.uuid,
            ...organisation
          });

          analytics.track(
            'Account Created',
            {
              user: userSub,
              // @ts-ignore
              email: data.email,
              account_name: teamName
            },
            {
              context: {
                groupId: organisation.slug
              }
            }
          );

          analytics.track(
            'Signed Up',
            {
              type: 'organic',
              // @ts-ignore
              name: data.name,
              // @ts-ignore
              email: data.email
            },
            {
              context: {
                groupId: organisation.slug
              }
            }
          );
        }
      } catch (e: any) {
        setMessage({
          text: e?.message || 'An error has occurred',
          level: 'error'
        });
      }
    }
    setStep((step) => step + 1);
  };

  const getInner = () => {
    switch (step) {
      case FormSteps.UserInfo:
        return <UserInfo onNext={handleNext} />;
      case FormSteps.OrgInfo:
        return <OrgInfo onNext={handleNext} />;
      case FormSteps.LicenceTerms:
        return <LicenseTerms onNext={handleNext} loading={loading} />;
      case FormSteps.Success:
        return <SubmitComplete />;

      case FormSteps.SubmitError:
        return <SubmitComplete />;
      default:
    }
  };
  return (
    <>
      <Head>
        <title>{'Create account'}</title>
      </Head>
      <AuthLayout title="Create a new account">{getInner()}</AuthLayout>
    </>
  );
};

export default CreateOrg;
