import React, { FunctionComponent, useEffect, useState } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Tab as TabType } from './types';

export type TabProps = TabType;

export type TabsProps = {
  tabs: Array<TabProps>;
};

const tabCurrentStyle = 'border-indigo-500 text-indigo-600';
const tabDefaultStyle =
  'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300';

const Tab: FunctionComponent<TabType> = ({ name, href }) => {
  // let location = useLocation();
  const [current, setCurrent] = useState(false);

  useEffect(() => {
    setCurrent(location.pathname === href);
  }, [location]);

  return (
    <Link
      key={name}
      to={href}
      className={classNames(
        current ? tabCurrentStyle : tabDefaultStyle,
        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
      )}
      aria-current={current ? 'page' : undefined}
    >
      {name}
    </Link>
  );
};

const Tabs: FunctionComponent<TabsProps> = ({ tabs }) => {
  return (
    <div className="mt-3 sm:mt-4">
      <div className="sm:hidden">
        <label htmlFor="current-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="current-tab"
          name="current-tab"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          // defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <Tab key={tab.name} {...tab} />
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
