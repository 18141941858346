import { FC } from 'react';

const CardFooter: FC = ({ children }) => {
  return (
    <div className="flex justify-end px-4 py-6 bg-gray-50 sm:px-6">
      {children}
    </div>
  );
};

export default CardFooter;
