import { FC } from 'react';

import { Form, Formik } from 'formik';
import { useHistory as useRouter } from 'react-router-dom';

import validateMatchingPassword from 'components/forms/validation/validateMatchingPassword';
import Head from 'components/Head';
import AuthButton from 'components/ui/AuthButton';
import Textbox from 'components/ui/Textbox';
import useQuery from 'hooks/useQuery';
import { useUser, ResetProps } from 'hooks/useUser';
import AuthLayout from 'layouts/AuthLayout';

interface ResetPasswordForm extends ResetProps {
  confirmPassword: string;
}

const ResetPassword: FC = () => {
  const router = useRouter();
  const { code, email } = useQuery();
  const { resetPassword, loading } = useUser();

  const onSuccess = () => {
    router.replace('/auth/signin');
  };

  const initialValues: ResetPasswordForm = {
    code,
    email,
    password: '',
    confirmPassword: '',
    onSuccess
  };

  return (
    <>
      <Head>
        <title>{'Reset your password'}</title>
      </Head>
      <AuthLayout title="Reset your password">
        <Formik
          initialValues={initialValues}
          onSubmit={resetPassword}
          validate={validateMatchingPassword}
          validateOnChange={false}
        >
          <Form className="space-y-6">
            <Textbox
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              required
            />

            <Textbox
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              required
            />

            <AuthButton loading={loading} text="Reset Password" />
          </Form>
        </Formik>
      </AuthLayout>
    </>
  );
};

export default ResetPassword;
