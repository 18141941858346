import { FC } from 'react';

import { Helmet } from 'react-helmet';

import { useSiteConfig } from '../../hooks/useSiteConfig';

const defaultDescription =
  'We use the latest climate science to create tools that support decision-makers to visualise, assess and respond to climate risk for Australia and the globe.';

const defaultMeta = {
  description: defaultDescription,
  'og:description': defaultDescription,
  'og:image':
    'https://www.climate-resource.com/images/ClimateResourcesLogoTeal_og.png',
  'og:locale': 'en_EN',
  'og:site_name': 'Climate Resource',
  'og:type': 'website',
  'twitter:site': '@ClimateRsrc',
  'twitter:card': 'summary_large_image'
};

interface HeadDefaultsProps {
  onUpdate: (meta: any) => void;
}

const HeadDefaults: FC<HeadDefaultsProps> = ({ onUpdate }) => {
  const { siteConfig } = useSiteConfig();
  const meta = { ...defaultMeta, ...(siteConfig.meta || {}) };

  return (
    <Helmet onChangeClientState={(newState) => onUpdate(newState)}>
      {Object.keys(meta).map((key) => {
        return <meta key={key} name={key} content={meta[key]} />;
      })}
    </Helmet>
  );
};

export default HeadDefaults;
