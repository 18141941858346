import { FC } from 'react';

import Loading from '../Loading';

type AuthButtonProps = {
  loading: boolean;
  text: string;
  onClick?: () => void;
};

const AuthButton: FC<AuthButtonProps> = ({ loading, text, onClick }) => {
  const handleOnClick = () => {
    if (!loading && onClick) {
      onClick();
    }
  };

  return (
    <div>
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={handleOnClick}
      >
        {!loading ? text : <Loading />}
      </button>
    </div>
  );
};

export default AuthButton;
