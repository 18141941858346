import React, { FunctionComponent } from 'react';

type TableEmptyStateProps = {
  label: string;
};

const TableEmptyState: FunctionComponent<TableEmptyStateProps> = ({
  label
}) => {
  return (
    <div className="m-32 w-full mx-auto flex justify-center">
      <div className="flex mx-auto">{label}</div>
    </div>
  );
};

export default TableEmptyState;
