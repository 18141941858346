import { FC } from 'react';

import FeedbackModal from '../Feedback/FeedbackModal';
import { Twitter } from '../ui/Icons';
import FooterColumn from './FooterColumn';

const navigation = {
  support: [
    // { name: 'Plans' + '', href: '/support' },
    {
      name: 'Report a Bug',
      render: () => {
        return <FeedbackModal />;
      }
    }

    // {
    //   name: 'Documentation',
    //   href: 'https://docs.climate-resource.com/pricing'
    // },
    // { name: 'API Status', href: '#' }
  ],
  company: [
    { name: 'About', href: 'https://climate-resource.com/about-us' }
    // { name: 'Jobs', href: 'https://climate-resource.com/careers' },
    // { name: 'Press', href: 'https://climate-resource.com/news' }
  ],
  legal: [
    { name: 'Privacy', href: '/privacy-policy' },
    { name: 'Terms', href: '/terms' },
    { name: 'License' + '', href: '/license' }
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/ClimateRsrc',
      icon: (props) => <Twitter {...props} />
    }
  ]
};

const Footer: FC = () => {
  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 grid md:grid-cols-3 xl:grid-cols-5 gap-8">
          <FooterColumn items={navigation.support} name="Support" />
          <FooterColumn items={navigation.legal} name="Legal" />
          <FooterColumn items={navigation.company} name="Team" />
          <div className="xl:col-span-2 md:col-span-3">
            <div className="flex flex-col">
              <p className="text-base text-gray-300">
                <a href="https://live.magicc.org" className="underline">
                  live.magicc.org
                </a>{' '}
                is developed and maintained by{' '}
                <a href="https://climate-resource.com" className="underline">
                  Climate Resource
                </a>
              </p>
              <div className="mt-0">
                <a href="https://climate-resource.com">
                  <img
                    className="h-14 w-auto mx-auto"
                    alt="Climate Resource"
                    src="/ClimateResourcesLogoTeal.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2021 Climate Resource PTY LTD. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
