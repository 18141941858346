import { FC, Fragment } from 'react';

import { Transition } from '@headlessui/react';

interface DefaultTransitionProps {
  show: boolean;
}

const DefaultTransition: FC<DefaultTransitionProps> = ({ show, children }) => {
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {children}
    </Transition>
  );
};

export default DefaultTransition;
