import { FC, ReactElement, Suspense, useEffect, useState } from 'react';

import {
  match,
  Redirect,
  Route,
  Switch,
  useRouteMatch
} from 'react-router-dom';

import { useSiteConfig } from 'hooks/useSiteConfig';
import ErrorPage from 'pages/error';
import { Feature } from 'typings/Feature';

import PrivateRoute from './components/PrivateRoute';
import useFeatures from './hooks/useFeatures';
import { BasicLayout } from './layouts/BasicLayout';
import AcceptInvite from './pages/accept-invite';
import AccountMembers from './pages/account/members';
import AccountProfile from './pages/account/profile';
import AccountSettings from './pages/account/settings';
import AuthCreateOrg from './pages/auth/create-org';
import AuthForgotPassword from './pages/auth/forgot-password';
import AuthRegister from './pages/auth/register';
import AuthResetPassword from './pages/auth/reset-password';
import AuthSignIn from './pages/auth/signin';
import AuthSignOut from './pages/auth/signout';
import AuthVerifyEmail from './pages/auth/verify-email';
import FallbackPage from './pages/fallback';
import ForceError from './pages/force-error';
import OrganisationCreate from './pages/organisation/create';
import OrganisationMissing from './pages/organisation/missing';
import PrivacyPolicy from './pages/privacy';
import Terms from './pages/terms';
import { Component } from './typings/Component';
import { load } from './utils/urls';

const Dashboard = load(() => import('./pages/dashboard'));

const ScenariosList = load(() => import('./pages/scenarios'));
const ScenarioOverview = load(() => import('./pages/scenarios/overview'));
const ScenarioCompare = load(() => import('./pages/scenarios/compare'));
const ScenarioCreate = load(() => import('./pages/scenarios/create'));
const ScenarioUpdate = load(() => import('./pages/scenarios/update'));

const DAERoutes = load(() => import('./custom/dae/routes'));
const IEARoutes = load(() => import('./custom/iea/routes'));

// Marketing routes
const LicensePage = load(() => import('./pages/marketing/license'));
const LandingPage = load(() => import('./pages/marketing/landing-page'));
const UoMInteractive2022 = load(() => import('./pages/uom/interactive-2022'));

const { DAE, IEA, Pipeline } = Feature;

interface RoutesProps {
  onUpdate: (route: match<{}>) => void;
}
const Routes: FC<RoutesProps> = ({ onUpdate }) => {
  const route = useRouteMatch();

  const { hasFeatures } = useFeatures();
  const { siteConfig, hasSiteComponent } = useSiteConfig();
  const [redirectUrl, setRedirectUrl] = useState(
    siteConfig.redirect || '/dashboard'
  );

  useEffect(() => {
    setRedirectUrl(siteConfig.redirect || '/dashboard');
  }, [siteConfig]);

  useEffect(() => {
    onUpdate(route);
  }, [route]);

  const maybeRender = (
    requiredComponent: Component,
    requiredFeatures: Feature[],
    routeFunc: () => ReactElement
  ) => {
    if (hasSiteComponent(requiredComponent)) {
      if (!requiredFeatures.length || hasFeatures(requiredFeatures)) {
        return routeFunc();
      }
    }
  };

  return (
    <Switch>
      {maybeRender(Component.Auth, [], () => (
        <Route path={'/auth'}>
          <Switch>
            <Route path="/auth/signin" exact component={AuthSignIn} />
            <Route path="/auth/signout" exact component={AuthSignOut} />
            {/*Create a new user account*/}
            <Route path="/auth/register" exact component={AuthRegister} />
            {/*Create a new org/user account*/}
            <Route path="/auth/create-org" exact component={AuthCreateOrg} />
            <Route
              path="/auth/forgot-password"
              exact
              component={AuthForgotPassword}
            />
            <Route
              path="/auth/reset-password"
              exact
              component={AuthResetPassword}
            />
            <Route
              path="/auth/verify-email"
              exact
              component={AuthVerifyEmail}
            />
            <Route component={() => <ErrorPage statusCode={404} />} />
          </Switch>
        </Route>
      ))}
      <Route path="/accept-invite/:member_id/:token" component={AcceptInvite} />
      <Route path="/organisation/missing" component={OrganisationMissing} />
      <Route path="/terms" component={Terms} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />

      <Route exact path="/public/scenarios/compare">
        <BasicLayout>
          <ScenarioCompare isPublicView />
        </BasicLayout>
      </Route>
      <Route exact path="/public/scenarios/:uuid">
        <BasicLayout>
          <ScenarioOverview isPublicView />
        </BasicLayout>
      </Route>

      {maybeRender(Component.DAE, [DAE], () => (
        <Route path="/dae">
          <DAERoutes />
        </Route>
      ))}

      {maybeRender(Component.IEA, [IEA], () => (
        <Route path="/iea">
          <IEARoutes />
        </Route>
      ))}

      {maybeRender(Component.Marketing, [], () => (
        <Route exact path="/" component={LandingPage} />
      ))}

      {maybeRender(Component.Marketing, [], () => (
        <Route exact path="/license" component={LicensePage} />
      ))}

      {maybeRender(Component.Marketing, [], () => (
        <Route exact path="/uom/interactive-2022">
          <BasicLayout>
            <UoMInteractive2022 />
          </BasicLayout>
        </Route>
      ))}

      <Route exact path="/force-error" component={ForceError} />

      <PrivateRoute>
        {/*Everything below requires a logged in user*/}
        <BasicLayout>
          <Suspense fallback={<FallbackPage />}>
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/fallback" component={FallbackPage} />

              {maybeRender(Component.Account, [], () => (
                <Route path={'/account'}>
                  <Route path="/account/settings" component={AccountSettings} />
                  <Route path="/account/members" component={AccountMembers} />
                  <Route path="/account/profile" component={AccountProfile} />
                </Route>
              ))}

              <Route
                path="/organisation/create"
                component={OrganisationCreate}
              />

              {maybeRender(Component.Pipeline, [Pipeline], () => (
                <Route path="/scenarios">
                  <Switch>
                    <Route
                      exact
                      path="/scenarios/create"
                      component={ScenarioCreate}
                    />
                    <Route
                      exact
                      path="/scenarios/compare"
                      component={ScenarioCompare}
                    />
                    <Route
                      exact
                      path="/scenarios/:uuid/overview"
                      component={ScenarioOverview}
                    />
                    <Route
                      exact
                      path="/scenarios/:uuid/update"
                      component={ScenarioUpdate}
                    />
                    <Redirect
                      exact
                      path="/scenarios/:uuid"
                      to="/scenarios/:uuid/overview"
                    />
                    <Route exact path="/scenarios" component={ScenariosList} />
                  </Switch>
                </Route>
              ))}

              <Redirect exact path="/" to={redirectUrl} />
              <Route component={() => <ErrorPage statusCode={404} />} />
            </Switch>
          </Suspense>
        </BasicLayout>
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
