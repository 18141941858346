import { createContext, useContext, useState, useEffect, FC } from 'react';

import { Auth } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { callApi } from 'utils/api';
import { canEdit as canEditCheck, IncludesOrg } from 'utils/permissions';

import { User, UserDetails, UserMessage } from '../typings/User';
import analytics from '../utils/analytics';
import { buildAuthConfig } from '../utils/auth';

interface EmailProp {
  email: string;
}

interface EmailPasswordProps extends EmailProp {
  password: string;
}

export interface RegisterProps extends EmailPasswordProps {
  next?: string;
  name?: string;
  subscribe?: boolean;
}

export interface ResetProps extends EmailPasswordProps {
  code: string;
  onSuccess: () => void;
}

export interface VerifyProps {
  email: string;
  code: string;
  next?: string;
}

type SetMessageCallback = (message: UserMessage) => void;
type SignInCallback = (props: EmailPasswordProps) => Promise<User>;
type RegisterCallback = (props: RegisterProps) => Promise<string | null>;
type ResetPasswordCallback = (props: ResetProps) => void;
type VerifyEmailCallback = (props: VerifyProps) => void;
type ForgotPasswordCallback = (props: EmailProp) => void;
type SignOutCallback = () => void;

export type Permission = string;

interface DefaultUserContextValues {
  user: User;
  loading: boolean;
  message: UserMessage;
  userDetails: UserDetails | null;
}

interface UserContextValues extends DefaultUserContextValues {
  fetchUserDetails: () => Promise<UserDetails>;
  setMessage: SetMessageCallback;
  signIn: SignInCallback;
  register: RegisterCallback;
  resetPassword: ResetPasswordCallback;
  verifyEmail: VerifyEmailCallback;
  forgotPassword: ForgotPasswordCallback;
  signOut: SignOutCallback;
  canEdit: (item: IncludesOrg) => boolean;
}

Auth.configure(
  buildAuthConfig({
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID
  })
);

export const UserContext = createContext<
  DefaultUserContextValues | UserContextValues
>({
  user: null,
  loading: true,
  message: null,
  userDetails: null
});

const getOrigin = () => {
  return window.location.origin;
};

export const UserContextProvider: FC = (props) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>(null);
  const [message, setMessage] = useState<UserMessage>(null);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(setUser)
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const fetchUserDetails = (): Promise<UserDetails> => {
    // Fetch the user details from the api
    // @ts-ignore
    return callApi<UserDetails>('/v1/me', user).then((userDetails) => {
      const organisation = userDetails.organisation;
      if (organisation) {
        // @ts-ignore
        analytics.plugins.segment.group(organisation.slug, {
          id: organisation.uuid,
          ...organisation
        });
      }

      analytics.identify(userDetails.uuid, {
        email: userDetails.email,
        // @ts-ignore
        name: user?.attributes?.name,
        features: userDetails.features,
        organisation: userDetails.organisation
      });
      setUserDetails(userDetails);
      setLoading(false);
      return userDetails;
    });
  };

  const handleSignIn: SignInCallback = async ({ email, password }) => {
    setLoading(true);
    setMessage(null);

    try {
      let user = await Auth.signIn(email, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log(user);
        user = await Auth.completeNewPassword(
          user, // the Cognito User Object
          password // the new password
        );
      }
      setUser(user);
      setMessage(null);

      analytics.track(
        'Signed In',
        {
          username: userDetails?.uuid
        },
        {
          context: {
            groupId: userDetails?.organisation?.slug
          }
        }
      );
    } catch (err: any) {
      console.error(err);
      setUser(null);
      setMessage({ text: err.message, level: 'error' });
    }
    setLoading(false);

    return user as CognitoUser;
  };

  const handleSignOut = () => {
    if (user) {
      analytics.track(
        'Signed Out',
        {
          username: userDetails?.uuid
        },
        {
          context: {
            groupId: userDetails?.organisation?.slug
          }
        }
      );

      user.signOut();
    }
    setUser(null);
    setUserDetails(null);
    setLoading(false);
    analytics.reset();
  };

  const handleRegister: RegisterCallback = async ({
    email,
    password,
    name,
    subscribe,
    next
  }) => {
    setLoading(true);
    setMessage(null);
    let userSub: string | null;
    try {
      const clientMetadata: Record<string, string> = { origin: getOrigin() };
      if (next !== undefined) {
        clientMetadata.next = next;
      }
      const resp = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name,
          'custom:subscribe': subscribe ? 'true' : 'false'
        },
        clientMetadata: clientMetadata
      });
      setMessage({
        text: 'Account created. Check your email to verify your account',
        level: 'success'
      });
      userSub = resp.userSub;
    } catch (err: any) {
      console.error(err);
      userSub = null;
      setMessage({ text: err.message as string, level: 'error' });
      setUser(null);
    }

    setLoading(false);
    return userSub;
  };

  const handleResetPassword: ResetPasswordCallback = async ({
    email,
    code,
    password,
    onSuccess
  }) => {
    setLoading(true);
    setMessage(null);

    try {
      const resp = await Auth.forgotPasswordSubmit(email, code, password);
      console.log(resp);
      setMessage({
        text: 'Successfully reset password',
        level: 'success'
      });
      onSuccess();
    } catch (err: any) {
      console.error(err);
      setMessage({ text: err.message as string, level: 'error' });
    }

    setLoading(false);
  };

  const handleVerifyEmail: VerifyEmailCallback = async ({ email, code }) => {
    setLoading(true);
    setMessage(null);

    try {
      const resp = await Auth.confirmSignUp(email, code);
      setMessage({
        text: 'Your email address has been verified.',
        level: 'success'
      });
      console.log(resp);
    } catch (err: any) {
      console.error(err);
      setMessage({ text: err?.message, level: 'error' });
    }
    setLoading(false);
  };

  const handleForgotPassword: ForgotPasswordCallback = async ({ email }) => {
    setLoading(true);
    setMessage(null);

    try {
      const resp = await Auth.forgotPassword(email, { origin: getOrigin() });
      setMessage({
        text: 'Check your email account for a link to reset your password',
        level: 'success'
      });
      console.log(resp);
    } catch (err: any) {
      console.error(err);
      setMessage({ text: err.message, level: 'error' });
    }
    setLoading(false);
  };

  const handleCanEdit = (item: IncludesOrg): boolean => {
    if (!userDetails) {
      return false;
    }
    return canEditCheck(userDetails, item);
  };

  useEffect(() => {
    if (user) {
      fetchUserDetails().catch((err) => {
        setMessage({ text: err.message, level: 'error' });
      });
    }
  }, [user]);

  const value = {
    user,
    loading,
    message,
    userDetails,
    fetchUserDetails,
    setMessage: setMessage,
    signIn: handleSignIn,
    register: handleRegister,
    resetPassword: handleResetPassword,
    forgotPassword: handleForgotPassword,
    verifyEmail: handleVerifyEmail,
    signOut: handleSignOut,
    canEdit: handleCanEdit
  };
  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = (): UserContextValues => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserContextProvider');
  }

  return context as UserContextValues;
};

export default useUser;
