import React, { Suspense } from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import APIConfig from 'components/APIConfig';
import { HeadDefaults } from 'components/Head';
import { UserContextProvider } from 'hooks/useUser';
import ErrorPage from 'pages/error';
import FallbackPage from 'pages/fallback';
import reportWebVitals from 'reportWebVitals';

import Routes from './routes';
import { onPageChange, onPageMetaChange } from './utils/analytics';

import 'utils/shims';
import 'styles/index.css';
import 'styles/chrome-bug.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  ignoreErrors: ['Non-Error exception captured'],
  release: process.env.REACT_APP_SENTRY_RELEASE
});

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={(fallbackProps) => <ErrorPage {...fallbackProps} />}
    >
      <HeadDefaults onUpdate={onPageMetaChange} />
      <UserContextProvider>
        <APIConfig>
          <Router>
            <Suspense fallback={<FallbackPage />}>
              <Routes onUpdate={onPageChange} />
            </Suspense>
          </Router>
        </APIConfig>
      </UserContextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
