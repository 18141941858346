import { FC, useMemo } from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  Row as TableRow,
  Column,
  useTable,
  useSortBy,
  TableOptions,
  useRowSelect,
  useMountedLayoutEffect
} from 'react-table';

import HeaderItem from './HeaderItem';
import LoadingCell from './LoadingCell';
import Row from './Row';
import TableEmptyState from './TableEmptyState';

const styles = {
  wrapper: 'py-2 align-middle inline-block min-w-full',
  shadow: 'overflow-hidden border-b border-gray-200 sm:rounded-lg',
  table: 'min-w-full divide-y divide-gray-200',
  header:
    'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
};

export interface TableProps extends TableOptions<any> {
  className?: string;
  clickableRow?: (row: TableRow, i: number) => string;
  loading?: boolean;
  hover?: boolean;
  isSelectable?: boolean;
  loadingRowCount?: number;
  placeholder?: string;
  selectedRowIds?: Record<string, boolean>;
  onSelectedRowsChange?: (rows: TableRow[]) => void;
}

const Table: FC<TableProps> = ({
  className,
  clickableRow,
  hover = true,
  loading = false,
  loadingRowCount = 3,
  placeholder = 'No data was found',
  onSelectedRowsChange,
  selectedRowIds,
  ...tableOptions
}) => {
  const memoizedData = useMemo(() => tableOptions.data, [tableOptions.data]);
  const memoizedColumns = useMemo(
    () => tableOptions.columns,
    [tableOptions.columns]
  );

  const loadingColumns: Array<Column<any>> = useMemo(
    () =>
      tableOptions.columns.map((c, i) => ({
        Header: c.Header,
        accessor: i.toString(),
        Cell: LoadingCell
      })),
    [tableOptions.columns]
  );

  const createLoadingRow = () => {
    return Object.fromEntries(
      tableOptions.columns.map((x, i) => [[i.toString()], undefined])
    );
  };

  const loadingData = useMemo(() => {
    const loadingRow = createLoadingRow();
    return Array.from({ length: loadingRowCount }, () => loadingRow);
  }, [tableOptions.columns]);

  if (loading) {
    tableOptions.columns = loadingColumns;
    tableOptions.data = loadingData;
  } else {
    tableOptions.data = memoizedData || [];
    tableOptions.columns = memoizedColumns;
  }
  tableOptions = { ...tableOptions, minRows: 3 };

  if (selectedRowIds) {
    tableOptions.initialState = {
      ...tableOptions.initialState,
      selectedRowIds
    };
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds: localSelectedRowIds }
  } = useTable(tableOptions, useSortBy, useRowSelect);

  /** Make selectedRowIds available to parent but prevent
   *  triggering infinite rerenders **/
  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(rows);
  }, [onSelectedRowsChange, localSelectedRowIds]);

  const history = useHistory();
  const empty = tableOptions.data.length === 0;

  const getHeader = () => {
    return (
      <thead className="bg-gray-100 ">
        {headerGroups.map((headerGroup) => (
          // eslint-disable-next-line react/jsx-key
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <HeaderItem
                key={column.id}
                column={column}
                className={styles.header}
              />
            ))}
          </tr>
        ))}
      </thead>
    );
  };

  const getBody = () => {
    return rows.map((row, i) => {
      prepareRow(row);

      const handleClick = () => {
        if (clickableRow) {
          const href = clickableRow(row, i);
          history.push(href);
        }
      };

      return (
        <Row
          onClick={clickableRow && handleClick}
          row={row}
          i={i}
          key={i}
          hover={hover}
        />
      );
    });
  };

  return (
    <div className={classNames('flex flex-col my-3"', className)}>
      <div className="-my-2 overflow-x-auto ">
        <div className={styles.wrapper}>
          <div className={styles.shadow}>
            <table className={styles.table} {...getTableProps()}>
              {getHeader()}
              <tbody {...getTableBodyProps()}>{getBody()}</tbody>
            </table>

            {empty && <TableEmptyState label={placeholder} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
