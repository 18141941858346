import { useMemo, useState, FC } from 'react';

import { MinusIcon } from '@heroicons/react/outline';

import DateCell from 'components/tables/DateCell';
import Table from 'components/tables/Table';
import { OrganisationMember } from 'typings/User';

import CheckCell from '../../../components/tables/CheckCell';
import Button from '../../../components/ui/Button';

const RemoveButton = ({ row, onRemoveClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true); // Hopefully - this element will be removed once the remove is successful
    onRemoveClick(row.id);
  };

  return (
    <Button onClick={handleClick} disabled={clicked}>
      <MinusIcon /> Remove
    </Button>
  );
};

interface MemberListProps {
  members: OrganisationMember[];
  loading: boolean;
  onRemoveClick: (string) => void;
}

const MemberList: FC<MemberListProps> = ({
  members,
  loading,
  onRemoveClick
}) => {
  const data = members || [];
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'display_name',
        className: 'font-bold'
      },
      {
        Header: 'Added At',
        accessor: 'created_at',
        Cell: DateCell
      },
      {
        Header: 'Invite Pending',
        accessor: 'pending',
        Cell: CheckCell
      },
      {
        Header: '',
        accessor: 'remove',
        Cell: ({ row }) => (
          <RemoveButton row={row} onRemoveClick={onRemoveClick} />
        )
      }
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      className="my-3"
      hover={false}
      loading={loading}
      getRowId={(value) => value.uuid}
    />
  );
};

export default MemberList;
