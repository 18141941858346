// Features are feature flag which allow parts of the application to be turned off/on depending on the organisation
// These are independent of the deployment
export enum Feature {
  Account = 'account',
  Impacts = 'impacts',
  MAGICC = 'magicc',
  Pipeline = 'pipeline',
  Scenarios = 'scenarios',
  SharePublic = 'share-public',
  Trial = 'trial',

  // Client Specific
  DAE = 'dae',
  IEA = 'iea'
}

export type FeatureValue = boolean | string;
export type Features = Partial<Record<Feature, FeatureValue>>;
