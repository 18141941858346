import { FC } from 'react';

import Head from 'components/Head';
import { PageBody, PageHeading } from 'components/Page';
import MarkdownContainer from 'containers/MarkdownContainer';
import { BasicLayout } from 'layouts/BasicLayout';

const PrivacyPolicy: FC = () => {
  return (
    <BasicLayout>
      <Head>
        <title>{'Privacy Policy'}</title>
      </Head>
      <PageHeading title="Privacy Policy" column />
      <PageBody className="prose  mb-12" column>
        <MarkdownContainer path={'privacy-policy.md'} />
      </PageBody>
    </BasicLayout>
  );
};

export default PrivacyPolicy;
