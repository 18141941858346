import { FC, useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';

export interface MarkdownContainerProps {
  /* Path relative to "src/content" */
  path: string;
}

const MarkdownContainer: FC<MarkdownContainerProps> = ({ path }) => {
  const [data, setData] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const content = require(`../../content/${path}`);
    fetch(content)
      .then((resp) => resp.text())
      .then((text) => setData(text));
  }, []);

  return <ReactMarkdown>{data}</ReactMarkdown>;
};

export default MarkdownContainer;
