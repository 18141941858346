import * as React from 'react';
import { useEffect } from 'react';

import { useHistory as useRouter } from 'react-router-dom';

import useFeatures from 'hooks/useFeatures';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useUser } from 'hooks/useUser';
import FallbackPage from 'pages/fallback';
import { Feature } from 'typings/Feature';

const signinUrl = '/auth/signin';

const withAuthentication = <T extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<T>
): React.FC<T> => {
  return (props) => {
    const { user, userDetails, loading, signOut, setMessage } = useUser();
    const { siteConfig } = useSiteConfig();
    const { hasFeatures } = useFeatures();
    const router = useRouter();

    const loadingDom = <FallbackPage />;

    const checkUserRequiredFeatures = (): boolean =>
      !siteConfig.requiredFeatures || hasFeatures(siteConfig.requiredFeatures);

    const checkAllowTrialUsers = () => {
      return !(!siteConfig.allowTrialUsers && hasFeatures(Feature.Trial));
    };

    useEffect(() => {
      const shouldRedirect = !user && !loading;
      if (shouldRedirect) {
        console.log('redirecting user to signin');
        router.push(signinUrl);
      }

      // Check if the user should be able to view the site
      if (userDetails) {
        if (!checkUserRequiredFeatures() || !checkAllowTrialUsers()) {
          console.error(
            'User does not have the required features for this site'
          );
          setMessage({
            text: 'User does not have permission to login',
            level: 'error'
          });
          signOut();
        }

        if (!userDetails.organisation) {
          console.log('redirecting non-org user');
          router.replace('/organisation/missing');
        }
      }
    }, [user, userDetails, loading, siteConfig]);

    // if there's a loggedInUser, show the wrapped page, otherwise show a loading indicator
    return user && userDetails ? <WrappedComponent {...props} /> : loadingDom;
  };
};

export default withAuthentication;
