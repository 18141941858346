import { FC } from 'react';

import { Helmet, HelmetProps } from 'react-helmet';

import { useSiteConfig } from '../../hooks/useSiteConfig';

const Head: FC<HelmetProps> = ({ titleTemplate, ...props }) => {
  const { siteConfig } = useSiteConfig();

  props.titleTemplate =
    props.titleTemplate || siteConfig.titleTemplate || '%s - Climate Resource';
  return <Helmet titleTemplate={titleTemplate} {...props} />;
};

export default Head;
