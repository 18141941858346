import { FC } from 'react';

import { Card, CardBody } from 'components/Card';
import ApiForm from 'components/forms/ApiForm';
import BooleanField from 'components/forms/BooleanField';
import Head from 'components/Head';
import { PageHeading, PageBody } from 'components/Page';
import Textbox from 'components/ui/Textbox';
import { Link } from 'components/ui/typography';

const OrganisationCreate: FC = () => {
  const onSubmitSuccess = () => {
    window.location.href = `/dashboard`;
  };

  return (
    <>
      <Head>
        <title>{'Create a New Organisation'}</title>
      </Head>
      <PageHeading title="Create a New Organisation" />
      <PageBody>
        <p>
          {`Organizations represent the top level in your hierarchy. You'll be
          able to bundle a collection of teams within an organization as well as
          give organization-wide permissions to users.
          `}
        </p>

        <Card sectionLabel="create">
          <CardBody>
            <ApiForm
              initialValues={{}}
              submitLabel={'Create Organisation'}
              apiEndpoint="/v1/organisations"
              apiMethod="POST"
              onSubmitSuccess={onSubmitSuccess}
            >
              <Textbox
                name="name"
                label="Organisation Name"
                placeholder={'e.g. My Company'}
                required
              />

              <BooleanField name="iAgree" required>
                I agree to the <Link href="/terms">Terms of Service</Link> and{' '}
                <Link href="/privacy-policy">Privacy Policy</Link>
              </BooleanField>
            </ApiForm>
          </CardBody>
        </Card>
      </PageBody>
    </>
  );
};

export default OrganisationCreate;
