import React, { useState, FC } from 'react';

import { Form, Formik } from 'formik';

import Loading from 'components/Loading';
import Button from 'components/ui/Button';
import { Variant } from 'components/ui/Button/Button';
import { callApi } from 'utils/api';

import FormState from './state';

interface ApiFormProps {
  initialValues: Record<any, any>;
  onSubmitSuccess: (any) => void;
  apiEndpoint: string;
  apiMethod: string;
  submitLabel: string;
  submitVariant?: Variant;
}

const ApiForm: FC<ApiFormProps> = ({
  initialValues,
  onSubmitSuccess,
  apiEndpoint,
  apiMethod,
  submitLabel,
  submitVariant,
  children
}) => {
  const [state, setState] = useState(FormState.READY);
  // eslint-disable-next-line
  const [data, setData] = useState({ ...initialValues });
  const [initialData, setInitialData] = useState({ ...initialValues });

  const onSubmit = async (values, { setStatus }) => {
    if (state === FormState.SAVING) return;
    await setState(FormState.SAVING);

    try {
      const resp = await callApi(apiEndpoint, {
        method: apiMethod,
        data: values
      });

      setState(FormState.READY);
      // @ts-ignore
      setInitialData({ ...data, ...(resp | {}) });

      onSubmitSuccess(resp);
    } catch (err) {
      setState(FormState.ERROR);
      setStatus(err);
    }
  };
  return (
    <Formik initialValues={initialData} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <div className="flex flex-col space-y-6">
            {children}
            <Button type="submit" variant={submitVariant}>
              {!isSubmitting ? submitLabel : <Loading />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApiForm;
