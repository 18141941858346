/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, ChangeEvent, ReactNode, ComponentProps } from 'react';

import classNames from 'classnames';

export type InputType =
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

export interface InputProps extends ComponentProps<'input'> {
  name?: string;
  placeholder?: string;
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
  value?: string | number;
  inputClasses?: string;
  fillLeftAddon?: boolean;
  fillRightAddon?: boolean;
  disabled?: boolean;
  type?: InputType;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const baseInputStyles =
  'appearance-none block w-full px-4 py-2 border border-gray-300 disabled:bg-gray-100 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm truncate';

const Input: FC<InputProps> = ({
  leftAddon,
  rightAddon,
  fillLeftAddon = false,
  fillRightAddon = true,
  disabled = false,
  name,
  value,
  type,
  inputClasses,
  placeholder,
  onChange = () => {},
  ...props
}) => {
  props.className = classNames(
    baseInputStyles,
    inputClasses,
    { 'pl-10': !!leftAddon },
    { 'pr-10': !!rightAddon },
    { 'pl-11': fillLeftAddon }
  );

  return (
    <label className="relative w-full">
      {leftAddon && (
        <div
          style={{
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5
          }}
          className={classNames(
            'pointer-events-none absolute h-10 w-10 sm:h-9 sm:w-9 mt-px ml-px  text-gray-400 top-0 z-10',
            { 'border-r border-gray-300 bg-gray-100': fillLeftAddon }
          )}
        >
          <div className="h5 w-5 mx-auto mt-2.5 sm:mt-2">{leftAddon}</div>
        </div>
      )}
      {/*@ts-ignore*/}
      <input
        name={name}
        placeholder={placeholder}
        type={type as string}
        disabled={disabled}
        value={value}
        onChange={onChange}
        {...props}
      />
      <div className="relative">
        {rightAddon && (
          <div
            style={{
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5
            }}
            className={classNames(
              'mb-px mr-px absolute bottom-0 right-0 h-10 w-10 sm:h-9 sm:w-9',
              {
                'border-l border-gray-300 bg-gray-100': fillRightAddon
              }
            )}
          >
            <div className="h5 w-5 mx-auto mt-2.5 sm:mt-2">{rightAddon}</div>
          </div>
        )}
      </div>
    </label>
  );
};

export default Input;
