import { FC, useState } from 'react';

import { Modal, ModalBody } from '../ui/Modal';
import FeedbackForm from './FeedbackForm';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeedbackModalProps {}

const FeedbackModal: FC<FeedbackModalProps> = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <a
        onClick={() => setOpen(true)}
        className="text-base text-gray-300 hover:text-white"
      >
        Bug Report
      </a>
      <Modal onClose={setOpen} open={open} width={'default'}>
        <ModalBody>
          <FeedbackForm onClose={() => setOpen(false)} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default FeedbackModal;
