import { FC } from 'react';

import { NavLink } from 'react-router-dom';

export const NavigationBarLoginButtons: FC = () => (
  <div className="ml-4 flex items-center md:ml-6">
    <NavLink to="/auth/signin">
      <button
        type="button"
        className="relative inline-flex items-center px-4 py-2 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
      >
        <span>{'Log in'}</span>
      </button>
    </NavLink>

    <NavLink to="/auth/create-org">
      <button
        type="button"
        className="relative inline-flex items-center px-4 py-2 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
      >
        <span>{'Sign up'}</span>
      </button>
    </NavLink>
  </div>
);
