import { FC } from 'react';

import { Form, Formik } from 'formik';

import AuthButton from 'components/ui/AuthButton';
import Textbox from 'components/ui/Textbox';
import { useUser } from 'hooks/useUser';
import AuthLayout from 'layouts/AuthLayout';

const initialValues = {
  email: ''
};

const ForgotPassword: FC = () => {
  const { forgotPassword, loading } = useUser();

  return (
    <AuthLayout title="Forgot your password?">
      <Formik initialValues={initialValues} onSubmit={forgotPassword}>
        <Form className="space-y-6">
          <Textbox
            name="email"
            type="email"
            label="Email"
            autoComplete="email"
            required
          />

          <AuthButton loading={loading} text={'Forgot Password'} />
        </Form>
      </Formik>
    </AuthLayout>
  );
};

export default ForgotPassword;
