import { FC } from 'react';

import { InformationCircleIcon } from '@heroicons/react/outline';

import Tooltip, { TooltipProps } from './Tooltip';

const InfoTooltip: FC<TooltipProps> = (props) => {
  return (
    <Tooltip {...props}>
      <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-600" />
    </Tooltip>
  );
};

export { InfoTooltip };
