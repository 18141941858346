import React, { FC } from 'react';

import classNames from 'classnames';

import { useUser } from 'hooks/useUser';

type Size = 'sm' | 'md' | 'lg';

const sizes: Record<Size, string> = {
  sm: 'h-6 w-6',
  md: 'h-10 w-10',
  lg: 'h-16 w-16'
};

const colours = [
  '#9CA3AF',
  '#F87171',
  '#FB923C',
  '#FBBF24',
  '#FACC15',
  '#4ADE80',
  '#2DD4BF',
  '#A3E635',
  '#22D3EE',
  '#34D399',
  '#60A5FA',
  '#818CF8',
  '#E879F9',
  '#A78BFA',
  '#C084FC',
  '#F472B6'
];

const hashIdentifier = (identifier: string): number => {
  identifier += '';
  let hash = 0;
  for (let i = 0; i < identifier.length; i++) {
    hash += identifier.charCodeAt(i);
  }
  return hash;
};

interface PlaceholderProps {
  initials: string;
}

const Placeholder = ({ initials }: PlaceholderProps) => {
  return (
    <span className="text-lg font-medium leading-none text-white">
      {initials}
    </span>
  );
};

export interface AvatarProps {
  size: Size;
}

const Avatar: FC<AvatarProps> = ({ size }) => {
  const { user, userDetails }: any = useUser();

  const name = user?.attributes?.name || userDetails?.email;

  const getColour = (identifier: string | undefined) => {
    if (identifier === undefined) {
      return '#9CA3AF';
    }

    const hash = hashIdentifier(userDetails.uuid);
    return colours[hash % colours.length];
  };

  const getInner = () => {
    const extractInitials = () => {
      if (name) {
        return name[0].toUpperCase();
      }
      return '?';
    };

    if (!userDetails) {
      return <Placeholder initials="?" />;
    }

    return <Placeholder initials={extractInitials()} />;
  };
  const colour = getColour(userDetails?.uuid);

  return (
    <div
      className={classNames(
        'inline-flex items-center justify-center rounded-full',
        sizes[size]
      )}
      style={{ backgroundColor: colour }}
    >
      {getInner()}
    </div>
  );
};

export default Avatar;
