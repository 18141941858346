import { FC } from 'react';

import useUser from '../../hooks/useUser';

const ProfileSummaryItem: FC = () => {
  const { userDetails, user } = useUser();

  // @ts-ignore
  const name = user?.attributes?.name || userDetails?.email;
  return (
    <div className="flex flex-col px-4 py-2 text-sm text-gray-900">
      <div className="text-sm font-semibold truncate">{name}</div>
      <div className="text-sm">{userDetails?.organisation?.name}</div>
    </div>
  );
};

export default ProfileSummaryItem;
