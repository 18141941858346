import { ResponsiveStyle } from 'typings/styles';

/**
 * Note: this approach is not recommended by Tailwind as it uses string concatenation
 * to generate styles and could lead to styling issues the future.
 *
 * Refer to Tailwinds documentation on [Writing purgeable HTML](https://tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html).
 */
export const getClassesByStyleProp = (
  twClass: string,
  styleProp: ResponsiveStyle
): string => {
  if (typeof styleProp === 'string' || typeof styleProp === 'number') {
    return `${twClass}-${styleProp}`;
  }

  if (typeof styleProp === 'object') {
    const classes: string[] = [];
    for (const [key, value] of Object.entries(styleProp)) {
      if (key === 'base') {
        classes.push(`${twClass}-${value}`);
      } else {
        classes.push(`${key}:${twClass}-${value}`);
      }
    }
    return classes.join(' ');
  }

  throw `[getClassesByStyleProp] type: ${typeof styleProp} is not supported by this function.`;
};

/** Takes a hex code and returns a lighter/darker shade based on percent.  */
export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};
