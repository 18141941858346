import { FC, forwardRef, useEffect, useRef } from 'react';

export interface CheckboxProps {
  label?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: any) => void;
  ref?: any;
}

const Checkbox: FC<CheckboxProps> = forwardRef(
  //@ts-ignore
  ({ name, checked, children, indeterminate, ...rest }, ref) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      //@ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            ref={resolvedRef}
            id={name}
            name={name}
            checked={checked}
            type="checkbox"
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            {...rest}
          />
        </div>

        {children && (
          <div className="ml-3 text-sm">
            <label htmlFor={name} className="font-medium text-gray-700">
              {children}
            </label>
          </div>
        )}
      </div>
    );
  }
);

export default Checkbox;
