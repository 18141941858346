import React, { FC, useState } from 'react';

import { PlusIcon } from '@heroicons/react/outline';
import useSWR, { mutate } from 'swr';

import { Paginated } from 'typings/API';
import { OrganisationMember } from 'typings/User';

import { Card, CardBody, CardHeader } from '../../../components/Card';
import InviteMembersModal from '../../../components/members/InviteMembersModal';
import Button from '../../../components/ui/Button';
import useFeatures from '../../../hooks/useFeatures';
import { Feature } from '../../../typings/Feature';
import { callApi } from '../../../utils/api';
import MemberList from './MemberList';

const MemberListCard: FC = () => {
  const [open, setOpen] = useState(false);
  const { data, isValidating } = useSWR<Paginated<OrganisationMember>>(
    `/v1/organisation/members`
  );
  const { hasFeatures } = useFeatures();

  const isTrialUser = hasFeatures(Feature.Trial);

  const headerActions = [
    {
      render: () => {
        return (
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant={'default'}
            disabled={isTrialUser}
            tooltipId={isTrialUser ? 'add-user' : undefined}
            tooltipLabel={
              'Multi-user teams are not available by default. Contact support to enable this feature.'
            }
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Add Member
          </Button>
        );
      }
    }
  ];

  const handleMemberRemove = async (uuid) => {
    await callApi(
      `/v1/organisation/members/${uuid}`,
      { method: 'delete' },
      false
    );
    mutate(`/v1/organisation/members`);
  };

  return (
    <Card sectionLabel="organisation-members">
      <CardHeader title="Organisation Members" actions={headerActions} />
      <CardBody>
        <MemberList
          members={data?.results || []}
          onRemoveClick={handleMemberRemove}
          loading={isValidating}
        />
        <InviteMembersModal
          open={open}
          onClose={(isOpen) => {
            console.log('isOpen', isOpen);
            if (!isOpen) setOpen(false);
          }}
        />
      </CardBody>
    </Card>
  );
};

export default MemberListCard;
