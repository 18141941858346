import { FC } from 'react';

import classNames from 'classnames';

export interface CardProps {
  sectionLabel: string;
  className?: string;
  fullHeight?: boolean;
}

const Card: FC<CardProps> = ({
  children,
  sectionLabel,
  className,
  fullHeight
}) => {
  return (
    <section
      aria-labelledby={sectionLabel}
      className={classNames({ 'h-full': fullHeight })}
    >
      <div
        data-testid="card"
        className={classNames(
          'shadow sm:rounded-md overflow-visible',
          { 'h-full': fullHeight },
          className
        )}
      >
        <div
          className={classNames('bg-white', {
            'h-full': fullHeight
          })}
        >
          {children}
        </div>
      </div>
    </section>
  );
};

export default Card;
