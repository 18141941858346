import { FC } from 'react';

import { useSiteConfig } from '../../hooks/useSiteConfig';
import CRLogo from './CRLogo';

export interface LogoProps {
  height?: string | number;
  width?: string | number;
}

const Logo: FC<LogoProps> = ({ height = 99, width = 375 }) => {
  const { siteConfig } = useSiteConfig();

  if (siteConfig.customerLogo) {
    return (
      <div>
        <img
          src={siteConfig.customerLogo}
          alt="Climate Resource"
          style={{ height }}
          className="w-auto"
        />
      </div>
    );
  }

  return <CRLogo height={height} width={width} />;
};

export default Logo;
