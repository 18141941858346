import { FC } from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import Input from './Input';
import { InputProps, InputType } from './Input/Input';
import Label from './Label';
import { InfoTooltip } from './Tooltip';

export interface TextboxProps extends InputProps {
  name?: string;
  id?: string;
  type?: InputType;
  disabled?: boolean;
  label: string;
  errorClasses?: string;
  autoComplete?: string;
  required?: boolean;
  placeholder?: string;
  tooltip?: string;
}

const Textbox: FC<TextboxProps> = ({
  label,
  errorClasses = 'text-red-600',
  tooltip,
  ...props
}) => {
  const itemName = props.name || props.id;

  if (!itemName) {
    throw Error('could not determine name');
  }

  const [field, meta] = useField(props as any);
  return (
    <div>
      <Label name={itemName}>
        {label}
        {props.required && <span className="ml-1">*</span>}
        {tooltip && (
          <span>
            <InfoTooltip id={'tooltip-' + itemName} label={tooltip} />
          </span>
        )}
      </Label>
      <div className="mt-1">
        <Input {...field} {...props} name={itemName} />
      </div>
      {meta.touched && meta.error ? (
        <div className={classNames('mt-1', errorClasses)}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Textbox;
