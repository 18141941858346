import { FC } from 'react';

import classNames from 'classnames';

export interface ButtonGroupProps {
  center?: boolean;
  className?: string;
}

const ButtonGroup: FC<ButtonGroupProps> = ({
  children,
  center = true,
  className
}) => {
  const buttonGroupStyle = classNames(
    'flex gap-4',
    { 'justify-center': center },
    className
  );

  return <div className={buttonGroupStyle}>{children}</div>;
};

export default ButtonGroup;
