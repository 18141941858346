import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import Badge from './Badge';

interface Item {
  value: string;
  isValid: boolean;
}

const InputItem = ({ value, isValid, onRemove }) => {
  return (
    <Badge
      removable={true}
      variant={isValid ? 'success' : 'error'}
      onRemove={onRemove}
    >
      {value}
    </Badge>
  );
};

interface MultiInputProps {
  onChange: (items: Item[], containsErrors: boolean) => void;
  placeholder: string;
  validateItem: (item: string, items: Item[]) => boolean;
}

const MultiInput: FC<MultiInputProps> = ({
  onChange,
  placeholder,
  validateItem = () => true,
  ...props
}) => {
  const [items, setItems] = useState<Item[]>([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleBlur = () => {
    if (inputValue) {
      const inputValueItems = inputValue.split(',').map((i) => i.trim());
      setItems((items) => {
        const newItems = inputValueItems.map((item) => ({
          value: item,
          isValid: validateItem(item, items)
        }));

        return [...items, ...newItems];
      });
      setInputValue('');
    }
  };

  const handleRemove = (item) => {
    setItems((items) => {
      return items.filter((i) => i.value !== item.value);
    });
  };

  useEffect(() => {
    const containsErrors = items.map((i) => !i.isValid).some((x) => x);
    onChange(items, containsErrors);
    setHasErrors(containsErrors);
  }, [items]);

  return (
    <div
      className={classNames(
        'block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm  sm:text-sm',
        {
          'border-red-300': hasErrors && items.length,
          'border-green-300': !hasErrors && items.length
        }
      )}
    >
      <div className="flex flex-row">
        {items.map((item) => (
          <InputItem
            key={item.value}
            {...item}
            onRemove={() => handleRemove(item)}
          />
        ))}
        <input
          className="block w-full py-1 focus:border-bg-grey-100 ml-1 py-0 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-100 focus:outline-none appearance-none"
          placeholder={items.length == 0 ? placeholder : undefined}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onBlur={handleBlur}
          {...props}
        />
      </div>
    </div>
  );
};

export default MultiInput;
