import segmentPlugin from '@analytics/segment';
import Analytics, { AnalyticsInstance } from 'analytics';

import mockAnalytics from './mockAnalytics';

const PAGE_VIEW_TIMEOUT = 3000;

interface Meta {
  title?: string;
}

const plugins: any[] = [];

const baseSegmentPlugin = segmentPlugin({
  writeKey: process.env.REACT_APP_SEGMENT || 'test'
});

const customSegmentPlugin = {
  ...baseSegmentPlugin,
  ...{
    track: (data) => {
      const { payload } = data;

      payload.properties = {
        siteCode: process.env.REACT_APP_SITE_CODE,
        ...payload.properties
      };

      baseSegmentPlugin.track({ ...data, payload });
    }
  }
};
plugins.push(customSegmentPlugin);

let analytics: AnalyticsInstance = mockAnalytics;
if (plugins)
  analytics = Analytics({
    app: 'platform',
    debug: true,
    plugins: plugins
  });

let routeTimeout: ReturnType<typeof setTimeout> | null = null;

export const onPageChange = (): void => {
  if (routeTimeout) {
    clearTimeout(routeTimeout);
  }

  routeTimeout = setTimeout(() => {
    analytics.page({
      siteCode: process.env.REACT_APP_SITE_CODE
    });
    routeTimeout = null;
  }, PAGE_VIEW_TIMEOUT);
};

export const onPageMetaChange = (meta: Meta): void => {
  if (meta.title && routeTimeout) {
    analytics.page({
      siteCode: process.env.REACT_APP_SITE_CODE,
      title: meta.title
    });

    clearTimeout(routeTimeout);
    routeTimeout = null;
  }
};

export default analytics;
