import { FC, ReactNode, useEffect, useState } from 'react';

import { PageBody } from 'components/Page';
import { getWithExpiry, setWithExpiry } from 'utils/storage';

import ErrorNotFound from './error/ErrorNotFound';
import ErrorUnhandled from './error/ErrorUnhandled';
import FallbackPage from './fallback';

export interface ErrorPageProps {
  title?: string | ReactNode;
  message?: string | ReactNode;
  statusCode?: string | number;
  eventId?: string | null;
  error?: Error;
}

const ErrorPage: FC<ErrorPageProps> = ({
  title = 'Roh Roh!',
  message = 'Something went wrong on our side.',
  eventId,
  statusCode,
  error
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        console.log('refreshing to get new chunks');
        window.location.reload();
      }
    }
    // We want to check if we can handle the error before showing the error page
    setShow(true);
  }, [error]);

  if (!show) {
    return <FallbackPage />;
  }

  return (
    <div className="bg-gray-100 h-screen">
      <PageBody>
        <div className="flex justify-center mt-0 sm:mt-16">
          <div className="flex flex-col text-center w-full">
            {statusCode === 404 ? (
              <ErrorNotFound />
            ) : (
              <ErrorUnhandled
                title={title}
                message={message}
                eventId={eventId}
              />
            )}
          </div>
        </div>
      </PageBody>
    </div>
  );
};

export default ErrorPage;
