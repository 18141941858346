import { FC } from 'react';

import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Avatar from '../ui/Avatar';
import DefaultTransition from '../ui/DefaultTransition';
import ProfileSummaryItem from './ProfileSummaryItem';

interface ProfileDropdownItem {
  name: string;
  href?: string;
  Component?: FC<any>;
}

const userNavigation: ProfileDropdownItem[] = [
  { name: 'Summary', Component: ProfileSummaryItem },
  { name: 'Settings', href: '/account/settings' },
  { name: 'Sign out', href: '/auth/signout' }
];

const ProfileDropdownMenuItem = ({
  name,
  href,
  Component = Link
}: ProfileDropdownItem) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <Component
          to={href}
          className={classNames(
            active ? 'bg-gray-100' : '',
            'block px-4 py-2 text-sm text-gray-700'
          )}
        >
          {name}
        </Component>
      )}
    </Menu.Item>
  );
};

export interface ProfileDropdownProps {
  name?: string;
  image?: string;
}

// TODO: wireup image prop
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProfileDropdown: FC<ProfileDropdownProps> = ({ name, image }) => {
  return (
    <>
      {name && <span data-testid="profile-dropdown-name">{name}</span>}
      <Menu as="div" className="ml-3 relative">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Open user menu</span>
                <Avatar size="md" />
              </Menu.Button>
            </div>
            <DefaultTransition show={open}>
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
              >
                {userNavigation.map((item) => (
                  <ProfileDropdownMenuItem key={item.name} {...item} />
                ))}
              </Menu.Items>
            </DefaultTransition>
          </>
        )}
      </Menu>
    </>
  );
};

export default ProfileDropdown;
