import React, { FC } from 'react';

import classNames from 'classnames';

export type BadgeVariant = 'default' | 'error' | 'warning' | 'success' | 'note';
export type BadgeSize = 'default' | 'lg';

const variants: Record<BadgeVariant, string> = {
  default: 'bg-gray-100 text-gray-800',
  error: 'bg-red-100 text-red-800',
  warning: 'bg-yellow-100 text-yellow-800',
  success: 'bg-green-100 text-green-800',
  note: 'bg-indigo-100 text-indigo-800'
};
const variantsRemove: Record<BadgeVariant, string> = {
  default:
    'text-grey-400 hover:bg-grey-200 hover:text-grey-500 focus:bg-grey-500',
  error: 'text-red-400 hover:bg-red-200 hover:text-red-500 focus:bg-red-500',
  warning:
    'text-yellow-400 hover:bg-yellow-200 hover:text-yellow-500 focus:bg-yellow-500',
  success:
    'text-green-400 hover:bg-green-200 hover:text-green-500 focus:bg-green-500',
  note: 'text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500'
};

const sizes: Record<BadgeSize, string> = {
  default: 'px-2.5 py-0.5 text-xs font-medium',
  lg: 'px-3 py-0.5  text-sm font-medium'
};

const RemoveButton = ({ variant, onClick }) => {
  return (
    <button
      type="button"
      className={classNames(
        'flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none  focus:text-white',
        variantsRemove[variant]
      )}
      onClick={onClick}
    >
      <span className="sr-only">Remove</span>
      <svg
        className="h-2 w-2"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 8 8"
      >
        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  );
};

interface BadgeProps {
  variant?: BadgeVariant;
  size?: BadgeSize;
  removable?: boolean;
  onRemove?: () => void;
}

const Badge: FC<BadgeProps> = ({
  children,
  variant = 'success',
  size = 'default',
  removable = false,
  onRemove
}) => {
  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-full',
        sizes[size],
        variants[variant]
      )}
    >
      <span>{children}</span>

      {removable ? (
        <RemoveButton
          data-testid="badge-remove-button"
          variant={variant}
          onClick={onRemove}
        />
      ) : undefined}
    </span>
  );
};

export default Badge;
