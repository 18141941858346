import { ReactNode } from 'react';

import { Variant } from '../ui/Button/Button';
import Icon from '../ui/Icon';

export interface SubHeading {
  label: string;
  Icon?: Icon;
}

export interface Tab {
  name: string;
  href: string;
}

export enum ActionType {
  Button,
  Dropdown,
  Node
}

export interface Action {
  type?: ActionType;
  label?: string;
  Icon?: Icon;
  href?: string;
  onClick?: () => void;
  variant?: Variant;
  node?: ReactNode;
}
