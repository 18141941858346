import { FC } from 'react';

import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface MenuItemProps {
  label?: string;
  href?: string;
}

const MenuItem: FC<MenuItemProps> = ({ label, href }) => {
  if (!href) {
    return null;
  }
  return (
    <Menu.Item>
      {({ active }) => (
        <Link
          to={href}
          className={classNames(
            active ? 'bg-gray-100' : '',
            'block px-4 py-2 text-sm text-gray-700'
          )}
        >
          {label}
        </Link>
      )}
    </Menu.Item>
  );
};

export default MenuItem;
