import { Organisation, UserDetails } from '../typings/User';

export interface IncludesOrg {
  organisation: Organisation;
}

const canEdit = (user: UserDetails, item: IncludesOrg): boolean => {
  const userOrg = user.organisation;
  if (typeof item.organisation === 'undefined') {
    throw Error('Could not determine object owner');
  }
  const itemOrg = item.organisation;

  return userOrg.uuid === itemOrg.uuid;
};

export { canEdit };
