import { FC, isValidElement, useState } from 'react';

import classNames from 'classnames';

import Loading from '../Loading';
import Button from '../ui/Button';
import { Action, ActionType } from './types';

export type PageActionProps = Action;

const { Button: ButtonType, Dropdown, Node } = ActionType;

const PageAction: FC<PageActionProps> = ({
  type = ButtonType,
  label,
  Icon,
  href,
  onClick,
  variant,
  node
}) => {
  const [loading, setLoading] = useState(false);

  if (type === Node && isValidElement(node)) {
    return node;
  }

  const handleOnClick = async () => {
    if (onClick) {
      setLoading(true);
      await onClick();
      setLoading(false);
    }
  };

  const getBody = () => {
    if (loading) {
      return (
        <>
          <Loading size={14} /> {label}
        </>
      );
    }

    return (
      <>
        {Icon && <Icon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />}
        {label}
      </>
    );
  };

  return (
    <span className={classNames({ 'hidden sm:block': type === Dropdown })}>
      <Button onClick={onClick && handleOnClick} href={href} variant={variant}>
        {getBody()}
      </Button>
    </span>
  );
};

export default PageAction;
