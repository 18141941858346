import { FC } from 'react';

import classNames from 'classnames';

import { ResponsiveStyle } from 'typings/styles';
import { getClassesByStyleProp } from 'utils/styles';

export interface PageBodyProps {
  column?: boolean;
  backgroundColor?: string;
  className?: string;
  paddingY?: ResponsiveStyle;
  paddingX?: ResponsiveStyle;
}

const PageBody: FC<PageBodyProps> = ({
  children,
  column = false,
  className,
  backgroundColor,
  paddingY = 6,
  paddingX = { base: 6, sm: 12 }
}) => {
  return (
    <div className={classNames('flex-grow', backgroundColor)}>
      <div
        className={classNames(
          'flex flex-col mx-auto',
          {
            'max-w-screen-xl': column
          },
          getClassesByStyleProp('px', paddingX),
          getClassesByStyleProp('py', paddingY),
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default PageBody;
