import { FC } from 'react';

import Head from 'components/Head';
import { Link } from 'components/ui/typography';

const ErrorNotFound: FC = () => {
  return (
    <>
      <Head>
        <title>{'Page Not Found'}</title>
      </Head>
      <div className="relative flex flex-col">
        <div className="relative z-10 w-full">
          <div className="text-6xl sm:text-9xl font-extrabold mt-56 sm:mt-44 text-teal">
            {404}
          </div>
          <div
            className="text-3xl sm:text-5xl font-bold mb-6 text-teal"
            data-testid="errorMessage"
          >
            {'Page Not Found'}
          </div>
          <div className="text-sm sm:text-xl font-medium mx-auto mb-6 text-gray-500">
            {"The page you're looking for was removed or does not exist."}
          </div>
          <Link
            className="mt-6 font-semibold hover:underline text-teal"
            href="/"
          >
            {'Return to dashboard'}
          </Link>
        </div>
        <svg
          className="absolute"
          width="100%"
          height="675"
          viewBox="0 0 708 697"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M591.158 128.263C655.476 197.53 695.862 284.736 705.834 381.411C715.307 478.584 694.366 585.225 630.048 642.034C566.228 698.345 459.529 705.321 368.287 689.873C276.546 674.425 200.76 637.051 134.946 580.741C69.6307 523.932 14.2871 448.187 2.32087 359.983C-9.14674 272.278 22.2646 172.614 88.0786 103.347C153.394 33.5814 253.112 -5.78611 346.848 0.692097C440.084 6.67198 527.338 58.4976 591.158 128.263Z"
            fill="#E5E7EB"
          />
        </svg>
      </div>
    </>
  );
};

export default ErrorNotFound;
