import { FC } from 'react';

import FooterItem, { FooterItemProps } from './FooterItem';

export interface FooterColumnProps {
  name: string;
  items: FooterItemProps[];
}

const FooterColumn: FC<FooterColumnProps> = ({ items, name }) => {
  return (
    <div>
      <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
        {name}
      </h3>
      <ul role="list" className="mt-4 space-y-4">
        {items.map((item) => (
          <FooterItem {...item} key={item.name} />
        ))}
      </ul>
    </div>
  );
};

export default FooterColumn;
