import React, { forwardRef, FunctionComponent } from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { FontWeight, BorderRadius, BoxShadow } from 'typings/styles';

import Tooltip from '../Tooltip';

export type Variant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning'
  | 'basic'
  | 'disabled'
  | 'ghost';
export type Size = 'default' | 'sm';

type ButtonElement = HTMLButtonElement & HTMLAnchorElement;
type AsType = 'a' | 'button' | 'link';

type ButtonType = 'button' | 'submit' | 'reset';

export type ButtonProps = {
  variant?: Variant;
  disabled?: boolean;
  size?: Size;
  fontWeight?: FontWeight;
  borderRadius?: BorderRadius;
  boxShadow?: BoxShadow;
  onClick?: (e?: React.MouseEvent<HTMLInputElement>) => void;
  href?: string;
  className?: string;
  forwardRef?: React.Ref<ButtonElement>;
  as?: AsType;
  type?: ButtonType;
  download?: boolean | string;
  tooltipLabel?: string;
  tooltipId?: string;
};

const defaultStyle =
  'inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer';

const disabledStyle =
  'bg-white hover:bg-white text-gray-300 border-gray-300 cursor-not-allowed';

const variantStyles: Record<Variant, string> = {
  default: 'bg-white hover:bg-gray-50 text-gray-700 border border-gray-300',
  primary: 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white',
  success: 'bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white',
  error: 'bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white',
  warning: 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-white',
  secondary: 'bg-indigo-50 text-indigo-500 hover:bg-indigo-100',
  disabled: disabledStyle,
  ghost: 'text-indigo-500 hover:bg-indigo-100',
  basic: ''
};

const sizes = {
  default: 'h-10 px-4 py-2 text-sm',
  sm: 'h-8 px-2 py-1 text-sm'
};

const wrapTooltip = (id, label, children) => {
  if (id && label) {
    return (
      <Tooltip id={id} label={label}>
        <div className="flex">{children}</div>
      </Tooltip>
    );
  }
  return children;
};

const BaseButton: FunctionComponent<ButtonProps> = ({
  children,
  variant = 'default',
  size = 'default',
  fontWeight = 'font-medium',
  borderRadius = 'rounded-md',
  boxShadow = 'shadow-md',
  onClick,
  href,
  disabled,
  className,
  forwardRef,
  as = 'button',
  type = 'button',
  download = false,
  tooltipLabel,
  tooltipId,
  ...props
}) => {
  const router = useHistory();

  disabled = disabled || variant == 'disabled';
  const handleOnClick = (e) => {
    if (!disabled) {
      if (onClick) {
        onClick(e);
      } else if (href) {
        router.push(href);
      }
    }
  };

  const buttonClasses = classNames(
    defaultStyle,
    fontWeight,
    borderRadius,
    { boxShadow: variant !== 'ghost' ? boxShadow : undefined },
    sizes[size],
    {
      [variantStyles[variant]]: !disabled,
      [disabledStyle]: disabled
    },
    className
  );

  if (as === 'a') {
    return (
      <a
        ref={forwardRef}
        className={buttonClasses}
        onClick={handleOnClick}
        {...props}
      >
        {wrapTooltip(tooltipId, tooltipLabel, children)}
      </a>
    );
  }
  if (as === 'link') {
    return (
      <a
        ref={forwardRef}
        className={buttonClasses}
        href={href}
        download={download}
        {...props}
      >
        {wrapTooltip(tooltipId, tooltipLabel, children)}
      </a>
    );
  }
  return (
    <button
      type={type}
      ref={forwardRef}
      disabled={disabled}
      className={buttonClasses}
      onClick={handleOnClick}
      {...props}
    >
      {wrapTooltip(tooltipId, tooltipLabel, children)}
    </button>
  );
};

const Button: FunctionComponent<ButtonProps> = forwardRef<
  ButtonElement,
  ButtonProps
>((props, ref) => {
  return <BaseButton forwardRef={ref} {...props} />;
});

export default Button;

Button.displayName = 'Button';
