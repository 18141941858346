import { FC } from 'react';

type LoadingCellProps = {
  value: any;
};

const LoadingCell: FC<LoadingCellProps> = () => {
  return <div className="rounded-md p-2 animate-pulse bg-gray-100 w-full" />;
};

export default LoadingCell;
