import { useState, FC } from 'react';

import { mutate } from 'swr';

import { callApi } from '../../utils/api';
import Badge from '../ui/Badge';
import Button from '../ui/Button';
import MultiInput from '../ui/MultiInput';

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

interface RequestError {
  email: string;
  error: string;
}

const ErrorMessage: FC<RequestError> = ({ email, error }) => {
  return <Badge variant="error">{error || email}</Badge>;
};

interface RequestStatus {
  sending: boolean;
  sent: number | null;
  errors: RequestError[];
}

const EmailInvite: FC = () => {
  const [invites, setInvites] = useState([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>({
    sending: false,
    sent: null,
    errors: []
  });

  const handleChange = (items) => {
    const validItems = items.filter((i) => i.isValid);

    setInvites(
      validItems.map((i) => ({
        email: i.value
      }))
    );
    setRequestStatus((requestStatus) => ({ ...requestStatus, sent: null }));
  };

  const sendInvite = async (invite) => {
    const data = {
      email: invite.email
    };

    const endpoint = `/v1/organisation/members`;

    try {
      await callApi(endpoint, { method: 'POST', data });
      setRequestStatus((requestStatus) => ({
        ...requestStatus,
        sent: (requestStatus.sent || 0) + 1
      }));
    } catch (err: any) {
      console.error(err);

      const requestError: RequestError = {
        email: invite.email,
        error: err.info?.email?.[0] || err.info.message || err.message
      };

      setRequestStatus((requestStatus) => ({
        sending: requestStatus.sending,
        sent: requestStatus.sent,
        errors: [...requestStatus.errors, requestError]
      }));
    }
  };

  const handleSendInvites = async () => {
    setRequestStatus(() => ({
      errors: [],
      sent: 0,
      sending: true
    }));

    await Promise.all(invites.map(sendInvite));

    mutate('/v1/organisation/members');

    setRequestStatus((requestStatus) => ({ ...requestStatus, sending: false }));
  };

  return (
    <div className="mt-12">
      <h2>Invite via email</h2>

      <div className="flex items-center mt-2 gap-4">
        <MultiInput
          onChange={handleChange}
          placeholder="Enter one or more email addresses"
          validateItem={emailIsValid}
        />
        <Button
          variant={
            requestStatus.sent && !requestStatus.sending ? 'success' : 'default'
          }
          disabled={requestStatus.sending}
          className="w-48"
          onClick={handleSendInvites}
        >
          {requestStatus.sent && !requestStatus.sending ? (
            `Sent ${requestStatus.sent} invites`
          ) : requestStatus.sending ? (
            <span>Sending</span>
          ) : (
            <span>Send Invites</span>
          )}
        </Button>
      </div>
      <div>
        {!requestStatus.sending &&
          requestStatus.errors.map((e) => (
            <ErrorMessage key={e.email} {...e} />
          ))}
      </div>
    </div>
  );
};

export default EmailInvite;
