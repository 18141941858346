import { FC } from 'react';

import { SWRConfig } from 'swr';

import { callApi } from 'utils/api';

const APIConfig: FC = ({ children }) => {
  return (
    <SWRConfig
      value={{
        fetcher: callApi
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default APIConfig;
