import { FC } from 'react';

import { Field } from 'formik';

import ApiForm from 'components/forms/ApiForm';
import { useSiteConfig } from 'hooks/useSiteConfig';

import Textarea from '../ui/Textarea';

export interface FeedbackProps {
  onClose: () => void;
}

const FeedbackForm: FC<FeedbackProps> = ({ onClose }) => {
  const { siteCode } = useSiteConfig();

  const initialValues = {
    url: window.location.href,
    source: siteCode,
    comment: ''
  };

  return (
    <div className="flex flex-col w-full">
      <h3 className="text-xl font-semibold">Bug Report/Suggestion</h3>
      <div className="my-6">
        Notice something wrong or have a suggestion on how we can improve? Let
        us know.
      </div>
      <ApiForm
        apiEndpoint="/v1/feedback"
        apiMethod="POST"
        submitLabel="Submit"
        onSubmitSuccess={onClose}
        initialValues={initialValues}
        submitVariant={'primary'}
      >
        <Field name="comment">
          {({ field }) => (
            <Textarea
              label="Comment"
              name="comment"
              rows={6}
              required
              {...field}
            />
          )}
        </Field>
      </ApiForm>
    </div>
  );
};

export default FeedbackForm;
