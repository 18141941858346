import { FC } from 'react';

import EmailInvite from 'components/members/EmailInvite';

import { Modal, ModalTitle, ModalBody } from '../ui/Modal';

interface InviteMembersModalProps {
  open: boolean;
  onClose: (isOpen: boolean) => void;
}

const InviteMembersModal: FC<InviteMembersModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} width="wide">
      <ModalTitle
        subtitle="Invite new members to join your organization."
        onCloseClick={onClose}
      >
        Invite new members
      </ModalTitle>
      <ModalBody>
        <EmailInvite />
      </ModalBody>
    </Modal>
  );
};

export default InviteMembersModal;
