import { FC } from 'react';

import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useUser } from 'hooks/useUser';

import Profile from '../Profile/Profile';
import NavigationBarItem from './NavigationBarItem';
import { NavigationBarLoginButtons as LoginButtons } from './NavigationLoginButtons';

const NavigationBar: FC = () => {
  const { siteConfig } = useSiteConfig();
  const { user } = useUser();

  const navItems = siteConfig.sidebar.items || [];

  const logoHeight = siteConfig.theme?.navbar?.logoHeight || 42;

  return (
    <Disclosure as="nav" className="bg-gray-300 shadow">
      {({ open }) => (
        <>
          <div className="mx-auto px-3 sm:px-4 md:px-10">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-200 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <Logo height={logoHeight} width="auto" />
                  </Link>
                </div>
                <div className="hidden flex items-center sm:flex sm:ml-6">
                  <div className="flex items-center space-x-4">
                    {navItems.map((item) => (
                      <NavigationBarItem
                        key={item.name}
                        {...item}
                        isMobile={false}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {user ? <Profile showName={false} /> : <LoginButtons />}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 flex flex-col">
              {navItems.map((item) => (
                <NavigationBarItem key={item.name} {...item} isMobile={true} />
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavigationBar;
