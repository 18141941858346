import { useEffect, FC } from 'react';

import { useHistory as useRouter } from 'react-router-dom';

import Logo from 'components/Logo';
import Alert from 'components/ui/Alert';
import { useUser } from 'hooks/useUser';

import useQuery from '../hooks/useQuery';
import { useSiteConfig } from '../hooks/useSiteConfig';

export type AuthLayoutProps = {
  title: string;
  redirect?: boolean;
};

const AuthLayout: FC<AuthLayoutProps> = ({
  title,
  redirect = true,
  children
}) => {
  const router = useRouter();
  const { siteConfig } = useSiteConfig();
  const { next } = useQuery();

  const { user, message } = useUser();

  useEffect(() => {
    if (redirect && user) {
      if (next) {
        router.replace(next);
      } else {
        router.replace(siteConfig.redirect || '/dashboard');
      }
    }
  }, [user, siteConfig]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="/">
          <Logo height={80} />
        </a>

        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {title}
        </h2>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {message && (
            <div className="mb-6" data-testid="login-message">
              <Alert variant={message.level}>{message.text}</Alert>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
