import { Fragment, FunctionComponent } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import Breadcrumbs from 'components/Breadcrumbs';

import MenuItem from '../ui/MenuItem';
import PageAction from './PageAction';
import SubHeading from './Subheading';
import Tabs from './Tabs';
import { Tab, Action, SubHeading as SubHeadingType, ActionType } from './types';

export interface PageHeadingProps {
  title?: string;
  subheadings?: SubHeadingType[];
  actions?: Action[];
  breadcrumbRoutes?: BreadcrumbsRoute[];
  showBreadcrumbs?: boolean;
  dropdown?: boolean;
  tabs?: Tab[];
  column?: boolean;
}

const PageHeading: FunctionComponent<PageHeadingProps> = ({
  title,
  subheadings,
  actions,
  breadcrumbRoutes = [],
  showBreadcrumbs = false,
  dropdown = false,
  tabs = undefined,
  children,
  column = false
}) => {
  return (
    <div className="bg-gray-50">
      <div
        className={classNames('flex flex-col  py-6 sm:px-12 px-6 mx-auto', {
          'max-w-screen-xl': column
        })}
      >
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="flex-1 min-w-0">
            {children}

            {!children && (
              <>
                {showBreadcrumbs ? (
                  <div className="flex flex-row flex-wrap md:flex-nowrap items-baseline my-1 font-bold text-3xl">
                    <Breadcrumbs
                      routes={breadcrumbRoutes}
                      className="text-gray-400 font-bold text-xl sm:text-3xl no-underline"
                    />
                    <span className="text-gray-400 ml-2 mr-3 text-xl sm:text-3xl">
                      {'>'}
                    </span>
                    <h1
                      className="truncate ... text-xl sm:text-3xl"
                      data-testid="page-heading"
                    >
                      {title}
                    </h1>
                  </div>
                ) : (
                  <h1
                    data-testid="page-heading"
                    className="mt-2 text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
                  >
                    {title}
                  </h1>
                )}
              </>
            )}

            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              {subheadings?.map((s) => (
                <SubHeading key={s.label} {...s} />
              ))}
            </div>
          </div>
          <div className="mt-5 flex lg:mt-0 lg:ml-4 gap-3">
            {actions &&
              actions.map((action, index) => (
                <PageAction
                  key={action.label || index}
                  type={dropdown ? ActionType.Dropdown : action.type}
                  {...action}
                />
              ))}

            {dropdown ? (
              <Menu as="span" className="ml-3 relative sm:hidden">
                {({ open }) => (
                  <>
                    <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      More
                      <ChevronDownIcon
                        className="-mr-1 ml-2 h-5 w-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {actions &&
                          actions.map((action) => {
                            return (
                              <MenuItem
                                key={action.label}
                                label={action.label}
                                href={action.href}
                              />
                            );
                          })}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            ) : undefined}
          </div>
        </div>
        {tabs ? <Tabs tabs={tabs} /> : undefined}
      </div>
    </div>
  );
};

export default PageHeading;
