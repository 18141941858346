import { FC } from 'react';

import Head from 'components/Head';
import { PageHeading, PageBody } from 'components/Page';

import MemberListCard from './components/MemberListCard';

const AccountSettings: FC = () => {
  return (
    <>
      <Head>
        <title>{'Settings'}</title>
      </Head>
      <PageHeading title="Account Settings" />
      <PageBody>
        <MemberListCard />
      </PageBody>
    </>
  );
};

export default AccountSettings;
