import { FC } from 'react';

import Loading from '../components/Loading';
import { PageBody } from '../components/Page';

const FallbackPage: FC = () => {
  return (
    <div data-testid="fallback">
      <PageBody>
        <div className="flex justify-center mt-16">
          <div className="flex flex-col">
            <div className="text-lg mb-6">
              <Loading size={80} />
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
};

export default FallbackPage;
