import { FC } from 'react';

import Head from 'components/Head';
import { PageBody, PageHeading } from 'components/Page';
import MarkdownContainer from 'containers/MarkdownContainer';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { BasicLayout } from 'layouts/BasicLayout';

const Terms: FC = () => {
  const { siteCode } = useSiteConfig();

  let termsContent = 'terms-generic.md';
  if (siteCode == 'magicc-live') {
    termsContent = 'terms-live-magicc.md';
  }
  return (
    <BasicLayout>
      <Head>
        <title>{'Terms of Service'}</title>
      </Head>
      <PageHeading title="Terms of Service" column />
      <PageBody className="prose mb-12" column>
        <MarkdownContainer path={termsContent} />
      </PageBody>
    </BasicLayout>
  );
};

export default Terms;
