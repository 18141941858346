import React, { FC } from 'react';

import ProfileDropdown from 'components/ProfileDropdown';
import { useUser } from 'hooks/useUser';

// import Notifications from 'components/ui/NotificationButton';

const staticProfileUrl =
  'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';

export interface ProfileProps {
  showName: boolean;
}

const Profile: FC<ProfileProps> = ({ showName = false }) => {
  const { userDetails } = useUser();

  return (
    <div className="ml-4 flex items-center md:ml-6">
      {/*<Notifications />*/}
      <ProfileDropdown
        name={(showName && userDetails?.email) || ''}
        image={staticProfileUrl}
      />
    </div>
  );
};

export default Profile;
