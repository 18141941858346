import React, { FC } from 'react';

import DateItem from '../ui/DateItem';

interface DateCellProps {
  value: string;
}

const DateCell: FC<DateCellProps> = ({ value }) => {
  return <DateItem date={value} />;
};

export default DateCell;
