import { FC } from 'react';

import { ExclamationIcon } from '@heroicons/react/solid';

import Head from 'components/Head';
import { Link } from 'components/ui/typography';
import { ErrorPageProps } from 'pages/error';

const ErrorUnhandled: FC<ErrorPageProps> = ({ title, message, eventId }) => {
  return (
    <>
      <Head>
        <title>{'Error'}</title>
      </Head>

      <div className="flex flex-col text-center relative">
        <ExclamationIcon
          style={{ top: '60px' }}
          className=" mx-auto absolute w-full h-32 text-gray-100"
        />
        <svg viewBox="0 0 200 200" className="h-64">
          <path
            fill="#005e72"
            d="M56.6,-65.5C72,-54.4,82.3,-35.4,83.6,-16.5C84.9,2.5,77.1,21.3,66.9,38.1C56.6,55,43.7,69.9,27.4,76.4C11,82.9,-8.9,80.9,-27.3,74.4C-45.7,67.9,-62.6,57,-73,41.1C-83.4,25.3,-87.1,4.6,-83.2,-14.2C-79.3,-33,-67.7,-49.9,-52.5,-61C-37.4,-72.1,-18.7,-77.4,0.9,-78.5C20.6,-79.6,41.1,-76.6,56.6,-65.5Z"
            transform="translate(100 100)"
          />
        </svg>

        <div className="text-3xl sm:text-5xl font-bold mb-4 ">{title}</div>

        <div className="text-xl font-semibold mb-2">{message}</div>
        {eventId && (
          <div className="text-xs text-gray-400">{`Event ID: ${eventId}`}</div>
        )}
        <Link className="mt-6 font-semibold hover:underline text-teal" href="/">
          {'Return to dashboard'}
        </Link>
      </div>
    </>
  );
};

export default ErrorUnhandled;
