/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';

import { useLocalStorage } from '@rehooks/local-storage';

import { Component } from '../typings/Component';
import { SiteConfig } from '../typings/SiteConfig';

const initialSiteCode = process.env.REACT_APP_SITE_CODE || 'public';
const initialSiteConfig = require('../../site-config/' +
  initialSiteCode) as SiteConfig;

type HasSiteComponent = (requiredComponent: Component) => boolean;

export interface SiteConfigProps {
  siteCode: string;
  siteConfig: SiteConfig;
  hasSiteComponent: HasSiteComponent;
}

export const useSiteConfig = (): SiteConfigProps => {
  const [siteCode] = useState<string>(() => initialSiteCode);
  const [siteConfig, setSiteConfig] = useState<SiteConfig>(
    () => initialSiteConfig
  );

  const [updatedSiteCode] = useLocalStorage('CI:site_code');

  useEffect(() => {
    if (updatedSiteCode) {
      const config = require('../../site-config/' + updatedSiteCode);
      if (config && config?.domain) {
        setSiteConfig(config);
      }
    }
  }, [updatedSiteCode]);

  const hasSiteComponent: HasSiteComponent = (requiredComponent) => {
    return siteConfig?.components?.includes(requiredComponent);
  };

  return {
    siteCode,
    siteConfig,
    hasSiteComponent
  };
};
