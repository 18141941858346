import { FunctionComponent } from 'react';

import {
  ExclamationIcon,
  XCircleIcon,
  CheckCircleIcon
} from '@heroicons/react/solid';
import classNames from 'classnames';

import Icon from './Icon';

export type AlertVariant = 'primary' | 'success' | 'error' | 'warning';

type AlertProps = {
  variant: AlertVariant;
  title?: string;
};

interface AlertStyles {
  bg: string;
  icon: string;
  title: string;
  body: string;
}

const variantIcons: Record<AlertVariant, Icon> = {
  error: XCircleIcon,
  primary: CheckCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationIcon
};

const variants: Record<AlertVariant, AlertStyles> = {
  error: {
    bg: 'bg-red-50',
    title: 'text-red-800',
    body: 'text-red-700',
    icon: 'text-red-400'
  },
  primary: {
    bg: 'bg-primary-50',
    title: 'text-primary-800',
    body: 'text-primary-700',
    icon: 'text-primary-400'
  },
  success: {
    bg: 'bg-green-50',
    title: 'text-green-800',
    body: 'text-green-700',
    icon: 'text-green-400'
  },
  warning: {
    bg: 'bg-yellow-50',
    title: 'text-yellow-800',
    body: 'text-yellow-700',
    icon: 'text-yellow-400'
  }
};

const Alert: FunctionComponent<AlertProps> = ({ children, title, variant }) => {
  const style: AlertStyles = variants[variant];
  const Icon: Icon = variantIcons[variant];
  return (
    <div data-testid="alert" className={classNames('rounded-md p-4', style.bg)}>
      <div className="flex">
        <div className="flex-shrink-0 mb-auto mt-auto">
          <Icon
            className={classNames('h-5 w-5', style.icon)}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {title && (
            <h3 className={classNames('text-sm font-medium', style.title)}>
              {title}
            </h3>
          )}
          <div className={classNames('text-sm', style.body)}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
