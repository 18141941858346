import { FC, useEffect } from 'react';

import { useHistory as useRouter } from 'react-router-dom';

import Head from 'components/Head';
import AuthButton from 'components/ui/AuthButton';
import useQuery from 'hooks/useQuery';
import { useUser } from 'hooks/useUser';
import AuthLayout from 'layouts/AuthLayout';

const VerifyEmail: FC = () => {
  const router = useRouter();
  const { code, email, next } = useQuery();
  const { loading, verifyEmail, setMessage } = useUser();

  useEffect(() => {
    verifyEmail({ email, code, next });
  }, []);

  const handleClick = () => {
    setMessage(null);
    router.replace(makeNextUrl('/auth/signin'));
  };

  const makeNextUrl = (path: string) => {
    if (next) {
      const params = new URLSearchParams({ next });
      return `${path}?${params.toString()}`;
    } else {
      return path;
    }
  };

  return (
    <>
      <Head>
        <title>{'Verify your email'}</title>
      </Head>
      <AuthLayout title="Verify your email">
        <AuthButton loading={loading} text="Sign In" onClick={handleClick} />
      </AuthLayout>
    </>
  );
};

export default VerifyEmail;
